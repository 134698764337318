import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { DocumentTextIcon, ExclamationCircleIcon, EyeIcon, ListBulletIcon, XMarkIcon } from '@heroicons/react/24/outline'

import ModalContainer from 'components/ModalContainer'
import { Button, Card, Table } from 'components/gsys-ui'
import { PaginatedTable } from 'components/gsys-ui/Table/Table'
import { useCustomers, useInvoice } from 'util/queries'

const ShowClusterModal = NiceModal.create(({ keycodes, viewCust }) => {
  const modal = useModal()
  const { isLoading, isError, error, data } = useCustomers()

  if (isLoading) return null

  const clusteredCusts = data.filter((el) => keycodes.includes(el.KeyCode))

  const handleViewCust = (acct) => {
    viewCust(acct)
    modal.remove()
  }

  return (
    <ModalContainer>
      <Card className="!w-[600px]">
        <Card.Title Icon={ListBulletIcon}>
          Grouped customers
        </Card.Title>
        <Card.Body>
          <CustTable data={clusteredCusts} viewCust={handleViewCust} />
          <div className="flex justify-end mt-2">
            <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Close</Button>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

const CustTable = ({ data, viewCust }) => {
  return (
    <PaginatedTable
      data={data}
      pageSize={10}
      padToPageSize={true}
      columns={[
        {
          header: 'ACCT',
          accessorKey: 'KeyCode',
          cellSize: 110
        },
        {
          header: 'NAME',
          accessorKey: 'Name'
        },
        {
          header: '',
          accessorKey: 'view',
          cellSize: 1,
          cellPad: false,
          cell: ({ row }) => (
            <div className="flex items-center">
              <Button small Icon={EyeIcon} onClick={() => viewCust(row.original.KeyCode)}>View</Button>
            </div>
          )
        }
      ]}
    />
  )
}

export default ShowClusterModal