import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { MapIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Map from 'components/Map'
import MechanicIcon from 'components/MechanicIcon'

import ModalContainer from 'components/ModalContainer'
import { Button, Card } from 'components/gsys-ui'
import { useTripDrops } from 'util/queries'

const MapModal = NiceModal.create(({ tripId }) => {
  const modal = useModal()
  const { isLoading, isError, error, data } = useTripDrops(tripId, { enabled: !!tripId })
  const custWithLoc = data ? data.filter((cust) => cust.Loc !== null && cust.Loc?.[0] !== null) : []
  const points = data ? custWithLoc.map((cust) => cust.Loc) : []

  console.log(data)

  if (isLoading || !data) return null

  return (
    <ModalContainer>
      <Card className="!w-[700px]">
        <Card.Title Icon={MapIcon}>
          Drops map
        </Card.Title>
        <Card.Body>
          <div className="w-full h-[700px]">
            <Map points={points}>
              {
                data && custWithLoc.map((el) => (
                  <MechanicIcon
                    key={el.Acct}
                    lat={el.Loc.lat}
                    lng={el.Loc.lon}
                    name={el.Name}
                  />
                ))
              }
            </Map>
          </div>
          <div className="flex justify-end mt-2 space-x-2">
            <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Close</Button>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default MapModal