import NiceModal from '@ebay/nice-modal-react'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ClockIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Avatar } from 'antd'
import { Card, Button, DateInput, Badge } from 'components/gsys-ui'
import Table, { PaginatedTable } from 'components/gsys-ui/Table/Table'
import moment from 'moment'
import { useState } from 'react'
import { groupByKey } from 'util/helpers'
import { useClocklog } from 'util/queries/tma'
import { BsDash } from 'react-icons/bs'
import clsx from 'clsx'

const ClocklogPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())

  const data = [
    {
      Name: 'Billy Buttstuff',
      Branch: 'BHAM',
      Position: 'Accounts',
      Dob: new Date(),
      SchedulePattern: 'Standard40h',
      CalendarEvents: [
        {
          EventType: 'CLOCK',
          Start: Date.parse('22 Apr 2024 05:58:00 GMT'),
          End: Date.parse('22 Apr 2024 15:10:00 GMT')
        },
        {
          EventType: 'CLOCK',
          Start: Date.parse('23 Apr 2024 06:03:00 GMT'),
          End: Date.parse('23 Apr 2024 15:10:00 GMT')
        },
        {
          EventType: 'CLOCK',
          Start: Date.parse('24 Apr 2024 06:35:00 GMT'),
          End: null
        }
      ],
      Schedule: [
        {
          Day: 1,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 2,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:10:00 GMT')
        },
        {
          Day: 3,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:20:00 GMT')
        },
        {
          Day: 6,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:30:00 GMT')
        },
        {
          Day: 7,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:40:00 GMT')
        }
      ]
    },
    {
      Name: 'Scroobius Pip',
      Branch: 'BHAM',
      Position: 'Accounts',
      Dob: new Date(),
      SchedulePattern: null,
      CalendarEvents: [
        {
          EventType: 'CLOCK',
          Start: Date.parse('22 Apr 2024 06:00:00 GMT'),
          End: Date.parse('22 Apr 2024 16:10:00 GMT')
        },
        {
          EventType: 'CLOCK',
          Start: Date.parse('23 Apr 2024 06:03:00 GMT'),
          End: Date.parse('23 Apr 2024 16:10:00 GMT')
        }
      ],
      Schedule: [
        {
          Day: 1,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 2,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 3,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 4,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 5,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        }
      ]
    },
    {
      Name: 'Jenny',
      Branch: 'PETERSFIELD',
      Position: 'Sales',
      Dob: new Date(),
      SchedulePattern: 'PartTime',
      CalendarEvents: [
        {
          EventType: 'CLOCK',
          Start: Date.parse('22 Apr 2024 12:00:00 GMT'),
          End: Date.parse('22 Apr 2024 17:10:00 GMT')
        },
        {
          EventType: 'CLOCK',
          Start: Date.parse('24 Apr 2024 12:03:00 GMT'),
          End: Date.parse('24 Apr 2024 15:10:00 GMT')
        }
      ],
      Schedule: [
        {
          Day: 1,
          Start: Date.parse('01 Jan 1970 12:00:00 GMT'),
          End: Date.parse('01 Jan 1970 17:00:00 GMT')
        },
        {
          Day: 3,
          Start: Date.parse('01 Jan 1970 12:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 4,
          Start: Date.parse('01 Jan 1970 12:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        }
      ]
    }
  ]

  const previousDay = () => {
    setSelectedDate(moment(selectedDate).subtract(1, 'day').toDate())
  }

  const nextDay = () => {
    setSelectedDate(moment(selectedDate).add(1, 'day').toDate())
  }

  const dataFiltered = data.map((staff) => {
    const filtered = {
      ...staff,
      CalendarEvents: staff.CalendarEvents.filter((el) => (
        moment(selectedDate).isBetween(el.Start, el.End)) ||
        moment(selectedDate).isSame(el.Start, 'day')
      ),
      Schedule: staff.Schedule.filter((el) => el.Day === moment(selectedDate).isoWeekday()).pop() || null
    }

    return {
      ...filtered,
      isSick: filtered.CalendarEvents.some((el) => el.EventType === 'SICK'),
      isHoliday: filtered.CalendarEvents.some((el) => el.EventType === 'HOLIDAY')
    }
  })

  const dataGrouped = groupByKey('Position', dataFiltered)

  return (
    <Card>
      <Card.Title bordered Icon={ClockIcon}>
        <div>CLOCKLOG</div>
        <div className="flex items-center space-x-2">
          <div className="">
            <Button variant="white" onClick={previousDay}><ChevronDoubleLeftIcon className="w-5 h-5" /></Button>
          </div>
          <div className="w-32">
            <DateInput value={selectedDate} onChange={setSelectedDate} />
          </div>
          <div className="">
            <Button variant="white" onClick={nextDay}><ChevronDoubleRightIcon className="w-5 h-5" /></Button>
          </div>
        </div>
      </Card.Title>
      {
        Object.keys(dataGrouped).map((key) => (
          <div className="border-b-[2px] last:border-b-0 border-b-gray-200 mb-2 last:mb-0">
            <Card.Title bordered Icon={BsDash}>
              <div>{key.toUpperCase()}</div>
            </Card.Title>
            <StaffTable data={dataGrouped?.[key] || []} selectedDate={selectedDate} />
          </div>
        ))
      }
    </Card>
  )
}

const DayColumnHeader = ({ timestamp, darken, highlight }) => {
  const ts = moment(timestamp)

  return (
    <div className={clsx(
      'text-center',
      darken && 'bg-gray-100',
      highlight && 'bg-yellow-200 border-l-[3px] border-r-[3px] border-yellow-300 outline-t-[3px] outline-yellow-300'
    )}>
      <div>{ts.format('ddd')} {ts.format('DD')}</div>
    </div>
  )
}

const StaffTable = ({ data, isLoading, selectedDate }) => {
  return (
    <Table
      data={data}
      isLoading={isLoading}
      rowHighlight={false}
      tableFixed={true}
      columns={[
        {
          header: 'EMPLOYEE',
          id: 'employee',
          cell: ({ row }) => <Employee data={row.original} />
        },
        {
          header: 'SHIFT',
          id: 'shift',
          cell: ({ row }) => {
            if (row.original.Schedule) {
              return <TimeBubbles start={row.original.Schedule.Start} end={row.original.Schedule.End} />
            } else {
              return null
            }
          }
        },
        {
          header: 'CLOCK IN',
          id: 'clockin',
          cell: ({ row }) => {
            const todayClock = row.original.CalendarEvents.filter((el) => el.EventType === 'CLOCK').pop()
            const scheduledClock = row.original.Schedule ? moment(row.original.Schedule.Start) : null
            const todayScheduled = scheduledClock ? moment(selectedDate).hour(scheduledClock.hour()).minute(scheduledClock.minute()).second(0).millisecond(0) : null

            if (todayClock?.Start) {
              return (
                <div className="flex items-center space-x-1">
                  <TimeBubbles start={todayClock.Start} variant="green" />
                  {
                    todayScheduled.isBefore(todayClock.Start) && (
                      <>
                        <Badge variant="red">{moment(todayClock.Start).diff(todayScheduled, 'minutes')} mins late</Badge>
                        <Button variant="white" small rounded><PencilSquareIcon className="w-4 h-4" /></Button>
                      </>
                    )
                  }
                </div>
              )
            } else {
              return null
            }
          }
        },
        {
          header: 'CLOCK OUT',
          id: 'clockout',
          cell: ({ row }) => {
            const todayClock = row.original.CalendarEvents.filter((el) => el.EventType === 'CLOCK').pop()
            console.log(todayClock)

            if (todayClock?.End) {
              return <TimeBubbles start={todayClock.End} variant="green" />
            } else {
              return null
            }
          }
        }
      ]}
    />
  )
}

const Employee = ({ data }) => {
  return (
    <div className="flex items-center">
      <div className="flex-none mr-2"><Avatar /></div>
      <div className="flex-1 space-y-1 w-full">
        <div className="font-bold text-md">{data.Name}</div>
        <div>
          {
            data.SchedulePattern && (
              <div className="inline-block px-1 py-0.5 text-xs bg-blue-100 rounded-full border border-blue-600 border-1">
                {data.SchedulePattern}
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

const TimeBubbles = ({ start, end, variant = 'blue' }) => {
  console.log(start, end)
  const bgColors = {
    blue: 'bg-blue-100',
    green: 'bg-green-100'
  }

  const borderColors = {
    blue: 'border-blue-600',
    green: 'border-green-600'
  }

  return (
    <div className="flex items-center">
      <div className={`inline-flex items-center p-2 rounded-full border ${bgColors[variant]} ${borderColors[variant]}`}>
        {moment(start).format('HH:mm')}
      </div>
      {
        end && (
          <>
            <BsDash className="w-4 h-4" />
            <div className={`inline-flex items-center p-2 rounded-full border ${bgColors[variant]} ${borderColors[variant]}`}>
              {moment(end).format('HH:mm')}
            </div>
          </>
        )
      }
    </div>
  )
}

const DayCell = ({ data, timestamp }) => {
  const currentDay = moment(timestamp)
  const currentDaySchedule = data.Schedule

  const Cont = ({ children, className }) => (
    <div className={clsx("relative h-20 border-l border-l-gray-200", className)}>
      <div className="flex absolute inset-0 flex-col justify-between p-2 space-y-1">
        {children}
      </div>
    </div>
  )

  if (!currentDaySchedule) {
    return (
      <Cont className="bg-gray-50"></Cont>
    )
  }

  const scheduleStartTime = moment(currentDaySchedule.Start)
  const scheduleEndTime = moment(currentDaySchedule.End)
  const currentDayStartTime = moment(currentDay).hour(scheduleStartTime.hour()).minute(scheduleStartTime.minute())
  const currentDayEndTime = moment(currentDay).hour(scheduleEndTime.hour()).minute(scheduleEndTime.minute())

  const currentDayClock = data.CalendarEvents.filter((el) => el.EventType === 'CLOCK' && moment(el.StartDate).isSame(currentDay, 'day')).pop()
  //const currentDaySick = data.CalendarEvents.filter((el) => el.EventType === 'SICK' && moment(el.))

  const startedLate = (
    moment().isAfter(moment(currentDayStartTime).add(5, 'minutes')) && (
      !currentDayClock?.StartDate ||
      moment(currentDayClock.StartDate).isAfter(moment(currentDayStartTime).add(5, 'minutes'))
    )
  )

  const endedEarly = (
    moment().isAfter(moment(currentDayEndTime)) && (
      currentDayClock?.EndDate &&
      moment(currentDayClock.EndDate).isBefore(scheduleEndTime)
    )
  )

  return (
    <Cont>
      <div className="inline-flex items-center space-x-2">
        {
          currentDayClock?.StartDate ? (
            <>
              <div className={clsx(
                'inline-flex items-center p-2 bg-green-100 rounded-full border border-green-600',
                !currentDayClock?.EndDate && 'bg-green-100 border-green-600'
              )}>
                {scheduleStartTime.format('HH:mm')}
              </div>
              <div className="mx-1">-</div>
            </>
          ) : (
            <>
              <div className="inline-flex items-center p-2 bg-blue-100 rounded-full border border-blue-600">
                {scheduleStartTime.format('HH:mm')}
              </div>
              <div className="mx-1">-</div>
            </>
          )
        }
        {
          currentDayClock?.EndDate ? (
            <div className="inline-flex items-center p-2 bg-green-100 rounded-full border border-green-600">
              {moment(currentDayClock.EndDate).format('HH:mm')}
            </div>
          ) : (
            <div className="relative">
              {/*<div className="absolute text-xs w-full text-center top-[-15px]">Finishing</div>*/}
              <div className="inline-flex items-center p-2 bg-blue-100 rounded-full border border-blue-600">
                {scheduleEndTime.format('HH:mm')}
              </div>
            </div>
          )
        }
      </div>
      <div className="space-x-1">
        {
          currentDayClock && !currentDayClock.EndDate && (
            <Badge variant="green">Working</Badge>
          )
        }
        {
          startedLate && (
            <Badge variant="red">Late</Badge>
          )
        }
      </div>
    </Cont>
  )
}

export default ClocklogPage