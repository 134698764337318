import clsx from 'clsx'
import RegPlate from 'components/RegPlate'
import { Card } from 'components/gsys-ui'
import moment from 'moment'

const DriverCard = ({ data }) => {
  const completedDrops = data.ActiveRuns.filter((drop) => drop.Status === 'Complete').length
  const pendingDrops = data.ActiveRuns.filter((drop) => drop.Status === 'Pending').length
  const voidedDrops = data.ActiveRuns.filter((drop) => drop.Status === 'Void').length
  const progress = Math.floor(((completedDrops) / (data.ActiveRuns.length)) * 100)

  return (
    <Card hover>
      <div className="p-1">
        <div className="flex mb-2">
          <div className="flex-none">
            <RegPlate>{data.Vehicle.Reg}</RegPlate>
          </div>
          <div className="flex-1">
            <div className="flex justify-end space-x-4">
              <div className="flex flex-col items-center">
                <div className="text-2xl font-bold leading-none">{moment(data.ActiveRuns[0].Date).format('HH:mm')}</div>
                <div className="text-sm select-none">START</div>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-2xl font-bold leading-none">{moment(data.ActiveRuns.slice(-1)[0].Eta).format('HH:mm')}</div>
                <div className="text-sm select-none">EST. RETURN</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div className="flex flex-col items-center">
            <div className="text-sm select-none">DROPS</div>
            <div className="text-lg font-bold leading-none">{data.ActiveRuns.length - 1}</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-sm select-none">INVOICES</div>
            <div className="text-lg font-bold leading-none">{data.ActiveRuns.reduce((acc, cur) => cur.Document ? acc + cur.Document.length : acc, 0)}</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-sm select-none">ITEMS</div>
            <div className="text-lg font-bold leading-none">{data.ActiveRuns.reduce((acc, cur) => acc + cur.totalParts, 0)}</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-sm select-none">VALUE</div>
            <div className="text-lg font-bold leading-none">£{data.ActiveRuns.reduce((acc, cur) => acc + cur.totalGoods, 0).toFixed(2)}</div>
          </div>
        </div>
      </div>
      <div className="ml-[-1px] mr-[-1px] mb-[-1px] bg-neutral-500 rounded-b-md px-2 py-1 text-white text-lg relative">
        <div className="flex items-center w-full">
          <div className="flex-none select-none text-[15px] mr-2 leading-normal">PROGRESS</div>
          <div className="flex-1">
            <ProgressBar done={progress} />
          </div>
        </div>
        <div className="flex justify-center mb-1 text-xs">
          {completedDrops} COMPLETE | {voidedDrops} VOIDED | {pendingDrops} PENDING
        </div>
      </div>
    </Card>
  )
}

const ProgressBar = ({ done = 0 }) => {
  return (
    <div className="w-full h-2 rounded-full bg-neutral-700">
      <div
        className={clsx(
          'h-full rounded-full transition-all',
          done < 25 && 'bg-red-500',
          done >= 25 && done < 50 && 'bg-yellow-500',
          done >= 50 && done < 75 && 'bg-blue-500',
          done >= 75 && 'bg-green-500'
        )}
        style={{ width: `${done}%` }}
      />
    </div>
  )
}

export default DriverCard