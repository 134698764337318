import { Field, ErrorMessage } from 'formik'

const Textarea = ({ name, label, ...props }) => (
  <div className="w-full">
    <label
      htmlFor={name}
      className="text-sm font-semibold"
    >
      {label}
      <Field
        as="textarea"
        name={name}
        aria-label={`Enter your ${label}`}
        className="p-2 w-full font-normal bg-white rounded border border-gray-300 transition-colors focus:border-blue-500 focus:outline-none"
        {...props}
      />
    </label>

    <div className="text-sm text-red-600">
      <ErrorMessage name={name} />
    </div>
  </div>
)

export default Textarea