const TwoColTable = ({ data }) => {
  return (
    <table>
      <tbody>
        {
          Object.keys(data).map((key, ind) => (
            <tr className="py-2">
              <td className="pr-3 font-bold">{key}</td>
              <td>{data[key]}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default TwoColTable