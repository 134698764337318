import { Button, InputFormik, DateInputFormik, Form, SwitchFormik } from 'components/gsys-ui'
import { CheckIcon, TruckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useModal } from '@ebay/nice-modal-react'

const VehicleForm = ({ isSubmitting, handleSubmit, error, isEditing = false }) => {
  const modal = useModal()

  return (
    <>
      <Form.Error>{error}</Form.Error>
      <Form.Header>Vehicle details</Form.Header>
      <Form.Row>
        <InputFormik name="Reg" label="Reg" disabled={isEditing} />
        <Form.VSpacer />
        <div className="flex justify-end">
          <SwitchFormik name="IsShared" label="Shared?" />
        </div>
      </Form.Row>
      <Form.Row>
        <InputFormik name="Make" label="Make" />
        <InputFormik name="Model" label="Model" />
        <InputFormik name="Colour" label="Colour" />
      </Form.Row>
      <Form.Spacer />
      <Form.Header>Fuel card</Form.Header>
      <Form.Row>
        <InputFormik name="FuelCardPin" label="Pin" />
        <DateInputFormik name="FuelCardExpiry" label="Expiry" />
        <InputFormik name="FuelCardSupplier" label="Supplier" />
      </Form.Row>
      <Form.Row>
        <InputFormik name="LastMileage" label="Last mileage" />
        <DateInputFormik name="LastService" label="Last service" />
        <Form.VSpacer />
      </Form.Row>
      <Form.Row>
        <InputFormik name="Area" label="Area" />
      </Form.Row>
      <Form.Row>
        <DateInputFormik name="LastCheckList" label="LastCheckList" />
        <InputFormik name="LastCheckListBy" label="LastCheckListBy" />
        <InputFormik name="CheckListSchedule" label="CheckListSchedule" />
      </Form.Row>
      <Form.Spacer />
      <Form.Footer>
        <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Cancel</Button>
        <Button Icon={CheckIcon} onClick={handleSubmit} loading={isSubmitting} variant="green">Submit</Button>
      </Form.Footer >
    </>
  )
}

export default VehicleForm