import clsx from 'clsx'

import Button from '../Button/Button'
import s from './Input.module.scss'
import { useField, ErrorMessage } from 'formik'

export const BaseInput = (props) => {
  return (
    <input
      {...props}
      className={clsx(
        s.input,
        props.formikError && s.error,
        props.className
      )}
    />
  )
}

export const Input = (props) => {
  return (
    <div className={s.inputGroup}>
      {
        props.label && (
          <div className={s.label}>{props.label}</div>
        )
      }
      <BaseInput {...props} />
      {
        !props.noError && (
          props.name ? (
            <div className={s.errorMessage}><ErrorMessage name={props.name} /></div>
          ) : (
            <div className={s.errorMessage}>{props.error}</div>
          )
        )
      }

    </div>
  )
}

export const InputFormik = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <Input
      {...props}
      {...field}
      label={label}
      formikError={!!meta.error}
    />
  )
}