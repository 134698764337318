import { useContext } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import ModalContainer from 'components/ModalContainer'
import { TruckIcon } from '@heroicons/react/24/outline'
import { Button, Card, Input, Form } from 'components/gsys-ui'
import { vehicleValidation } from 'util/validations'
import VehicleForm from 'components/vehicles/VehicleForm'
import { useUpdateVehicle } from 'util/mutations'

const EditVehicleModal = NiceModal.create(({ initial }) => {
  const modal = useModal()
  const mutation = useUpdateVehicle()

  const handleSubmit = (res) => {
    modal.remove()
  }

  return (
    <ModalContainer>
      <Card className="!w-[500px]">
        <Card.Title Icon={TruckIcon}>Edit a vehicle</Card.Title>
        <Card.Body>
          <Form
            validation={vehicleValidation}
            mutation={mutation}
            initial={initial}
            FormComp={VehicleForm}
            onSubmit={handleSubmit}
          />
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default EditVehicleModal