const RegPlate = ({ children, size = 1 }) => {
  return (
    <div
      className="flex justify-center items-center rounded-md border border-black flex-0"
      style={{
        backgroundColor: 'rgb(248, 208, 56)',
        width: `${160 * size}px`,
        height: `${40 * size}px`,
        fontSize: `${24 * size}px`,
        fontFamily: 'LicensePlate'
      }}
    >
      {children}
    </div>
  )
}

export default RegPlate