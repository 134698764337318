import NiceModal from '@ebay/nice-modal-react'
import { PlusIcon, PlusSmallIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Grid, Col, Card, Table, Button, Input, ButtonInput } from 'components/gsys-ui'
import moment from 'moment'
import { useState } from 'react'
import { useAddManifestSend, useDeleteManifestSend } from 'util/mutations'
import { useManifestSendEmails } from 'util/queries'

const Manifests = () => {
  const { isLoading, isError, error, data } = useManifestSendEmails()
  const [email, setEmail] = useState('')
  const mutation = useAddManifestSend()

  if (isLoading) return null

  const handleSubmit = () => {
    if (!/^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(email)) return
    mutation.mutate(email)
  }

  return (
    <Grid className="p-2">
      <Col sm={8}>
        <Card>
          <div className="flex p-2">
            <ButtonInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onClick={handleSubmit}
            >
              <PlusIcon className="mr-1 w-5 h-5" />Add email
            </ButtonInput>
          </div>
          <Table
            data={data ? data : []}
            cols={[
              {
                header: 'DATE',
                accessorKey: 'Date',
                cell: ({ row }) => moment(row.original.Date).format('DD/MM/YY HH:mm')
              },
              {
                header: 'EMAIL',
                accessorKey: 'Email'
              },
              {
                header: '',
                accessorKey: 'delete',
                cellSize: 20,
                disableSortBy: true,
                cellPad: false,
                cell: ({ row }) => (
                  <EmailDeleteButton
                    manifestSendId={row.original._id}
                  />
                )
              }
            ]}
          />
        </Card>
      </Col>
    </Grid>
  )
}

const EmailDeleteButton = ({ manifestSendId }) => {
  const mutation = useDeleteManifestSend()
  const [isLoading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    await mutation.mutateAsync(manifestSendId)
  }

  return (
    <div className="flex items-center h-full">
      <Button
        variant="rounded"
        tiny
        onClick={() => NiceModal.show('deleteModal', { name: 'manifest send', handleDelete })}
        loading={isLoading}
        Icon={TrashIcon}
      />
    </div>
  )
}

export default Manifests