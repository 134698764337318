import NiceModal from '@ebay/nice-modal-react'
import { PencilIcon, PlusSmallIcon, TrashIcon, TruckIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import RegPlate from 'components/RegPlate'
import { Col, Grid, Button, Card, Form, Switch, TwoColTable, Spinner } from 'components/gsys-ui'
import VehicleForm from 'components/vehicles/VehicleForm'
import { GlobalContext } from 'context/GlobalContext'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useAddVehicle, useDeleteVehicle, useUpdateVehicle } from 'util/mutations'
import { useVehicle, useVehicles } from 'util/queries'
import { vehicleValidation } from 'util/validations'

const Vehicles = () => {
  const { isLoading, isError, error, data } = useVehicles()
  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const { branch } = useContext(GlobalContext)

  useEffect(() => {
    setSelectedVehicle(null)
  }, [branch])

  if (isLoading || !data) return <Spinner size={30} cont={true} />

  if (!selectedVehicle && data.length > 0) {
    setSelectedVehicle(data[0].Reg)
  }

  return (
    <Grid gap={7} className="p-2 h-full">
      <Col lg={6}>
        <div className="mb-2">
          <Button Icon={PlusSmallIcon} onClick={() => NiceModal.show('vehiclesAddVehicleModal')}>Add a vehicle</Button>
        </div>
        {
          isLoading || !data ? (
            <Spinner size={30} cont={true} />
          ) : (
            <div className="space-y-2">
              {
                data && data.map((vehicle) => (
                  <VehicleCard
                    vehicle={vehicle}
                    selectedVehicle={selectedVehicle}
                    setSelectedVehicle={setSelectedVehicle}
                  />
                ))
              }
            </div>
          )
        }
      </Col>
      <Col lg={18}>
        {
          selectedVehicle && (
            <VehicleDetailsCard vehicleReg={selectedVehicle} setSelectedVehicle={setSelectedVehicle} />
          )
        }
      </Col>
    </Grid>
  )
}

const VehicleCard = ({ vehicle, selectedVehicle, setSelectedVehicle }) => {
  return (
    <Card
      hover
      onClick={() => setSelectedVehicle(vehicle.Reg)}
      className={clsx(
        selectedVehicle === vehicle.Reg && '!border-green-600 !border-[2px]',
        selectedVehicle !== vehicle.Reg && 'p-[1px]'
      )}
    >
      <Card.Title Icon={TruckIcon}>
        <span>{vehicle.IsShared ? 'Shared' : vehicle.Branch}</span>
        <RegPlate size={0.9}>{vehicle.Reg}</RegPlate>
      </Card.Title>
      <Card.Body>
        {vehicle.Make || 'No make'} - {vehicle.Model || 'No model'}{vehicle.Colour && `, ${vehicle.Colour}`}
      </Card.Body>
    </Card>
  )
}

const VehicleDetailsCard = ({ vehicleReg, setSelectedVehicle }) => {
  const { isLoading, isError, error, data } = useVehicle(vehicleReg)
  const mutation = useUpdateVehicle()
  const deleteMutation = useDeleteVehicle()

  const handleDelete = async () => {
    NiceModal.show('deleteModal', {
      handleDelete: async () => {
        await deleteMutation.mutateAsync(vehicleReg)
        setSelectedVehicle(null)
      },
      name: 'vehicle'
    })
  }

  if (isLoading || !data) return <Spinner size={30} cont={true} />

  return (
    <Card>
      <Card.Title>
        <RegPlate size={1.1}>{data.Reg}</RegPlate>
        <div className="flex items-center">
          <Switch
            label="Shared"
            value={data.IsShared}
            onChange={() => mutation.mutate({ Reg: data.Reg, IsShared: !data.IsShared })}
          />
          <div className="flex-none ml-4 space-x-2">
            <Button Icon={PencilIcon} onClick={() => NiceModal.show('vehiclesEditVehicleModal', { initial: data })}>
              Edit vehicle
            </Button>
            <Button Icon={TrashIcon} onClick={handleDelete} variant="red">Delete vehicle</Button>
          </div>
        </div>
      </Card.Title>
      <Card.Body>
        <Grid>
          <Col lg={8}>
            <div className="text-lg font-bold">Vehicle details</div>
            <TwoColTable
              data={{
                Branch: data.Branch,
                Make: data.Make,
                Model: data.Model,
                Colour: data.Colour,
                'Last Mileage': data.LastMileage
              }}
            />
          </Col>
          <Col lg={8}>
            <div className="text-lg font-bold">Fuel Card</div>
            <TwoColTable
              data={{
                Number: data.FuelCardNo,
                Pin: data.FuelCardPin,
                Expiry: moment(data.FuelCardExpiry).format('DD/MM/YYYY'),
                Supplier: data.FuelCardSupplier
              }}
            />
          </Col>
          <Col lg={8}>
            <div className="text-lg font-bold">Checklists</div>
            <TwoColTable
              data={{
                Last: moment(data.LastCheckList).format('DD/MM/YYYY'),
                By: data.lastCheckListBy,
                Schedule: data.CheckListSchedule,
                Area: data.Area
              }}
            />
          </Col>
        </Grid>
      </Card.Body>
    </Card>
  )
}

export default Vehicles