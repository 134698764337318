import parse from 'html-react-parser'
import { useEffect, useRef, useState } from 'react'

const PhonePreview = ({ html }) => {
  const contRef = useRef(null)
  const frameRef = useRef(null)
  const [scale, setScale] = useState(1)

  useEffect(() => {
    if (!contRef.current || !frameRef.current || !html) return
    const computed = getComputedStyle(contRef.current)
    const paddingV = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom)
    const paddingH = parseInt(computed.paddingLeft) + parseInt(computed.paddingRight)
    const internalHeight = contRef.current.clientHeight - paddingV
    const internalWidth = contRef.current.clientWidth - paddingH
    const frameWidth = frameRef.current.offsetWidth
    const scaleFactor = internalWidth / frameWidth
    setScale(scaleFactor)
  }, [contRef, frameRef, html])

  return (
    <div className="relative h-full grow-0">
      <div
        ref={contRef}
        className="absolute inset-0 z-[-1] bg-white rounded-md p-3 pt-12"
        style={{
          marginLeft: '5.376%',
          marginRight: '5.376%',
          marginTop: '4.704%',
          marginBottom: '4.704%'
        }}
      >
        {
          html && (
            <iframe
              ref={frameRef}
              style={{
                transform: `scale(${scale})`,
                transformOrigin: 'left top'
              }}
              className="absolute"
              width="576px"
              height="1249px"
              srcDoc={html}
              title="Preview"
            />
          )
        }
      </div>
      <img className="z-20 h-full pointer-events-none" src="/img/iphone.png" />
    </div>
  )
}

export default PhonePreview