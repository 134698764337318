import clsx from 'clsx'

import s from './Card.module.scss'

const Card = ({ children, ...props }) => {
  return (
    <div
      className={clsx(
        s.card,
        !!props.hover && s.hover,
        props.className
      )}
      style={props.style}
      onClick={props.onClick}
    >
      {props.body ? <Card.Body>{children}</Card.Body> : children}
    </div>
  )
}

const Title = ({ Icon, children, ...props }) => {
  return (
    <div className={clsx(
      s.title,
      props.bordered && s.bordered
    )}>
      {
        Icon && (
          <Icon className={s.icon} width="28px" height="28px" />
        )
      }
      <div className={clsx(s.content, props.className)}>
        {children}
      </div>
    </div>
  )
}

const Body = ({ children, ...props }) => {
  return (
    <div className={clsx(s.body, props.noPad && s.noPad, props.className)}>
      {children}
    </div>
  )
}

Card.Title = Title
Card.Body = Body

export default Card