import React, { useRef } from 'react'
import styles from './Spinner.module.scss'

const Spinner = React.memo(({ size = 40, color = 'rgba(0,0,0,0.2)' }) => {
  // prevents spinner jitter
  const mountTime = useRef(Date.now())
  const mountDelay = -(mountTime.current % 700)
  const width = size < 30 ? size < 20 ? 2 : 3 : 5

  return (
    <div
      className={styles.spinner}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderColor: `${color} ${color} transparent ${color}`,
        borderWidth: `${width}px`,
        '--spinner-delay': `${mountDelay}ms`
      }}
    />
  )
})

const SpinnerWithCont = React.memo(({ cont = false, ...props }) => {
  if (cont) {
    return (
      <div className="flex justify-center w-full">
        <Spinner {...props} />
      </div>
    )
  }

  return <Spinner {...props} />
})

export default SpinnerWithCont