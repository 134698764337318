import NiceModal from '@ebay/nice-modal-react'
import { CalendarDaysIcon, ClockIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { UserIcon } from '@heroicons/react/24/solid'
import { Avatar } from 'antd'
import { Card, Button, DateInput } from 'components/gsys-ui'
import { PaginatedTable } from 'components/gsys-ui/Table/Table'
import moment from 'moment'
import { useState } from 'react'
import { useClocklog, useHolidays, useSickDays, useStaff } from 'util/queries/tma'
import { FaUmbrellaBeach } from 'react-icons/fa'
import { BiPlusMedical } from 'react-icons/bi'
import clsx from 'clsx'
import { groupByKey } from 'util/helpers'

const CalendarPage = () => {
  const [fromDate, setFromDate] = useState(moment().startOf('month').toDate())
  const [toDate, setToDate] = useState(moment().endOf('month').toDate())

  const { isLoading: isLoadingHols, isError: isErrorHols, error: errorHols, data: dataHols } = useHolidays(fromDate, toDate)
  const { isLoading: isLoadingSick, isError: isErrorSick, error: errorSick, data: dataSick } = useSickDays(fromDate, toDate)
  const { isLoading: isLoadingStaff, isError: isErrorStaff, error: errorStaff, data: dataStaff } = useStaff()

  const isLoading = isLoadingHols || isLoadingSick || isLoadingStaff

  return (
    <Card>
      <Card.Title bordered Icon={CalendarDaysIcon}>
        <div>CALENDAR</div>
        <div className="flex items-center space-x-2">
          <div className="flex items-center mr-2 space-x-2">
            <Button variant="white" onClick={() => { }}>Prev month</Button>
          </div>
          <div className="w-32">
            <DateInput value={fromDate} onChange={setFromDate} />
          </div>
          <div className="text-gray-400">—</div>
          <div className="w-32">
            <DateInput value={toDate} onChange={setToDate} />
          </div>
          <div className="flex items-center ml-2 space-x-2">
            <Button variant="white" onClick={() => { }}>Next month</Button>
          </div>
        </div>
      </Card.Title>
      <CalendarTable
        dataStaff={dataStaff || []}
        dataHols={dataHols || []}
        dataSick={dataSick || []}
        isLoading={isLoading}
        fromDate={fromDate}
        toDate={toDate}
      />
    </Card>
  )
}

const checkDayInRange = (checkTs, calEntries) => {
  if (!calEntries) return [false, null]
  let inRange = false
  let halfDay = null

  for (const entry of calEntries) {
    const entryTs = moment(entry.StartDatetime)
    const daysDiff = checkTs.diff(entryTs, 'days') + 1

    if (daysDiff >= 0 && daysDiff <= Math.ceil(entry.TotalDays)) {
      inRange = true
    }

    if (entry.TotalDays === 0.5) {
      if (checkTs.isSame(entryTs, 'day')) {
        if (entryTs.hour() === 0) {
          halfDay = 'AM'
        } else {
          halfDay = 'PM'
        }
      }
    }
  }

  return [inRange, halfDay]
}

const CalendarTable = ({ dataStaff, dataHols, dataSick, isLoading, fromDate, toDate }) => {
  const dayCount = moment(toDate).diff(moment(fromDate), 'days') + 1
  const holsGroupedByStaffId = groupByKey('StaffID', dataHols)
  const sickGroupedByStaffId = groupByKey('StaffID', dataSick)

  const dataWithDays = dataStaff.map((staff, ind) => {
    const staffHolidays = holsGroupedByStaffId[staff.StaffId]
    const staffSicks = sickGroupedByStaffId[staff.StaffId]

    const days = new Array(dayCount).fill('ayylmao').reduce((acc, cur, ind) => {
      const currentDayTs = moment(fromDate).add(ind, 'days')
      const [holiday, holHalfDay] = checkDayInRange(currentDayTs, staffHolidays)
      const [sick, sickHalfDay] = checkDayInRange(currentDayTs, staffSicks)
      const halfDay = holHalfDay || sickHalfDay

      return {
        ...acc,
        [`Day${ind}`]: {
          Timestamp: currentDayTs.toDate(),
          Holiday: holiday,
          Sick: sick,
          HalfDay: halfDay
        }
      }
    }, {})

    return {
      FName: staff.FName,
      SName: staff.SName,
      Position: staff.Position,
      Branch: staff.Branch,
      ...days
    }
  })

  const columns = new Array(dayCount).fill('ayylmao').map((_, ind) => {
    const ts = moment(fromDate).add(ind, 'days')
    return calendarColumn(`Day${ind}`, ts.toDate())
  })

  return (
    <PaginatedTable
      data={dataWithDays}
      isLoading={isLoading}
      pageSize={15}
      padToPageSize={true}
      dummyRowHeight={33}
      columns={[
        {
          header: 'NAME',
          id: 'name',
          cell: ({ row }) => `${row.original.FName} ${row.original.SName}`
        },
        ...columns
      ]}
    />
  )
}

const CalendarColumnHeader = ({ timestamp, darken, highlight }) => {
  const ts = moment(timestamp)

  return (
    <div className={clsx(
      'text-center',
      darken && 'bg-gray-100',
      highlight && 'bg-yellow-200 border-l-[3px] border-r-[3px] border-yellow-300 outline-t-[3px] outline-yellow-300'
    )}>
      <div>{ts.format('DD')}</div><div>{ts.format('ddd')}</div>
    </div>
  )
}

const CalendarIcon = ({ highlight, darken, holiday, sick, halfDay }) => {
  const Cont = ({ children }) => (
    <div className={clsx(
      'w-11 h-10 flex items-center justify-center',
      darken && 'bg-gray-100',
      highlight && 'w-11 bg-yellow-100 border-l-[3px] border-l-yellow-300 border-r-[3px] border-r-yellow-300'
    )}>
      {children}
    </div>
  )

  const SimpleIcon = ({ Comp, className }) => {
    return (
      <Cont>
        <Comp className={clsx('w-8 h-8', className)} />
      </Cont>
    )
  }

  const DoubleIcon = ({ Left, Right, lClass, rClass }) => {
    return (
      <Cont>
        <div className="relative w-6 h-6">
          <Right className={clsx('absolute w-6 h-6 top-[-4px] left-[8px]', rClass)} />
          <Left className={clsx('absolute w-6 h-6 top-[4px] left-[0px]', lClass)} />
        </div>
      </Cont>
    )
  }

  if (holiday) {
    if (halfDay === 'AM') {
      return <DoubleIcon Left={FaUmbrellaBeach} Right={UserIcon} lClass="text-green-400" rClass="text-gray-300" />
    } else if (halfDay === 'PM') {
      return <DoubleIcon Left={UserIcon} Right={FaUmbrellaBeach} lClass="text-gray-300" rClass="text-green-400" />
    } else {
      return <SimpleIcon Comp={FaUmbrellaBeach} className="text-green-400" />
    }
  }

  if (sick) {
    if (halfDay === 'AM') {
      return <DoubleIcon Left={BiPlusMedical} Right={UserIcon} lClass="text-red-400" rClass="text-gray-300" />
    } else if (halfDay === 'PM') {
      return <DoubleIcon Left={UserIcon} Right={BiPlusMedical} lClass="text-gray-300" rClass="text-red-400" />
    } else {
      return <SimpleIcon Comp={BiPlusMedical} className="text-red-400" />
    }
  }

  return <SimpleIcon Comp={UserIcon} className="text-gray-300" />
}

const calendarColumn = (colKey, timestamp) => {
  const ts = moment(timestamp)
  const isToday = moment().isSame(ts, 'day')
  const isWeekend = ts.day() === 0 || ts.day() === 6 // sun and sat

  return {
    header: (
      <CalendarColumnHeader
        timestamp={ts}
        darken={isWeekend}
        highlight={isToday}
      />
    ),
    id: timestamp.getTime(),
    cellSize: 1,
    noPadAnywhere: true,
    cell: ({ row }) => {
      return (
        <CalendarIcon
          highlight={isToday}
          darken={isWeekend}
          holiday={row.original[colKey].Holiday}
          sick={row.original[colKey].Sick}
          halfDay={row.original[colKey].HalfDay}
        />
      )
    }
  }
}


export default CalendarPage