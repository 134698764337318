import Map from 'components/Map'
import MechanicIcon from 'components/MechanicIcon'
import RegPlate from 'components/RegPlate'
import { Card, Col, Grid } from 'components/gsys-ui'
import moment from 'moment'
import { useRewindRun } from 'util/queries'
import { apir } from 'util/haxios'
import { useState } from 'react'
import SearchField from './SearchField'
import { getTimeDifference } from 'util/helpers'
import Timeline from 'components/rewind/Timeline'

const Rewind = () => {
  const [runId, setRunId] = useState(null)
  const { isLoading, isError, error, data } = useRewindRun(runId)

  const points = data ? data.Drops.map((drop) => drop.Loc[0]) : []

  return (
    <Grid gap={7} className="p-2 h-full">
      <Col sm={9}>
        <div className="mb-2">
          <SearchField setRunId={setRunId} />
        </div>
        {
          !isLoading && data && (
            <Card className="px-3 pt-3">
              <div className="flex justify-between items-center mb-5 text-lg font-bold">
                <div className="flex items-center">
                  <div className="mr-2">
                    <RegPlate size={0.8}>{data.Vehicle.Reg}</RegPlate>
                  </div>
                  <div>{data.Vehicle.Branch}</div>
                </div>
                <div className="font-normal text-md">
                  {moment(data.Drops[0].StartDate).format('ddd Do MMM HH:mm')}
                  {' - '}
                  {moment(data.Drops[data.Drops.length - 1].CompletedDate).format('HH:mm')}
                  &nbsp;(
                  {
                    getTimeDifference(
                      data.Drops[0].StartDate,
                      data.Drops[data.Drops.length - 1].CompletedDate
                    )
                  }
                  )
                </div>
              </div>
              <Timeline runId={runId} />
            </Card>
          )
        }
      </Col>
      <Col sm={15}>
        <Map key={runId} points={points} noCenterPointsChange>
          {
            data && data.Drops.map((drop, ind) => {
              if (drop.Acct === '|none|') {
                return (
                  <div
                    className="flex flex-col items-center absolute translate-x-[-50%] translate-y-[-100%]"
                    key={ind}
                    lat={drop.Loc[0].lat}
                    lng={drop.Loc[0].lon}
                  >
                    <Card>
                      <img className="w-10 h-10" src={`${apir}/public/images/logo.jpg`} />
                    </Card>
                  </div>
                )
              }
              return (
                <MechanicIcon
                  key={ind}
                  lat={drop.Loc[0].lat}
                  lng={drop.Loc[0].lon}
                  name={`${drop.Name}`}
                />
              )
            })
          }
        </Map>
      </Col>
    </Grid>
  )
}



export default Rewind