import { Button, InputFormik, DateInputFormik, Form, SwitchFormik, Grid, Col, Textarea } from 'components/gsys-ui'
import { CheckIcon, TruckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useModal } from '@ebay/nice-modal-react'
import PhonePreview from './PhonePreview'
import ReactQuill from 'react-quill'
import TextEditor, { TextEditorFormik } from './TextEditor'
import { useFormikContext } from 'formik'

const BulletinForm = ({ isSubmitting, handleSubmit, error, isEditing = false }) => {
  const modal = useModal()
  const { values } = useFormikContext()

  return (
    <>
      <Grid gap={7}>
        <Col sm={6}>
          <Form.Row>
            <InputFormik name="Name" label="Name" />
          </Form.Row>
          <Form.Row>
            <Textarea rows={6} name="Notes" label="Notes" />
          </Form.Row>
          <Form.Row>
            <DateInputFormik name="DateTime" label="Date" />
          </Form.Row>
          <Form.Row>
            <DateInputFormik name="Expiry" label="Expiry" />
          </Form.Row>
        </Col>
        <Col sm={18}>
          <div className="flex">
            <div className="flex-1 pr-3 pl-1">
              <TextEditorFormik name="Content" opt={{ height: '576px' }} />
            </div>
            <div className="flex-none">
              <div className="h-[576px]">
                <PhonePreview html={values.Content} />
              </div>
            </div>
          </div>
        </Col>
      </Grid>
      <Form.Footer>
        <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Cancel</Button>
        <Button Icon={CheckIcon} onClick={handleSubmit} loading={isSubmitting} variant="green">Submit</Button>
      </Form.Footer >
    </>
  )
}

export default BulletinForm