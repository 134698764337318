import { loginValidation } from 'util/validations'
import { useLogin } from 'util/mutations'
import { Card, Button, InputFormik, Form } from 'components/gsys-ui'
import { useContext } from 'react'
import { GlobalContext } from 'context/GlobalContext'

const LoginPage = () => {
  const mutation = useLogin()

  return (
    <div className="flex justify-center items-center h-full">
      <Card className="px-10 py-8" style={{ width: '380px' }}>
        <div className="flex flex-col items-center">
          <img className="px-6 mb-4 h-[58px]" src="/img/logofull.png" />
          <div className="text-xl font-bold">Welcome</div>
          <div className="mb-8">Please log in to continue.</div>
          <Form
            validation={loginValidation}
            mutation={mutation}
            FormComp={LoginForm}
            redirect="/"
            onSubmit={() => window.location = '/'}
          />
        </div>
      </Card>
    </div>
  )
}

const LoginForm = ({ isSubmitting, handleSubmit, error }) => {
  const { loginError } = useContext(GlobalContext)

  return (
    <div className="w-full">
      <Form.Error>{loginError || error}</Form.Error>
      <Form.Row>
        <InputFormik name="username" label="Username" />
      </Form.Row>
      <Form.Row>
        <InputFormik
          type="password"
          name="password"
          label="Password"
          onKeyUp={(e) => e.keyCode === 13 && handleSubmit()}
        />
      </Form.Row>
      <Form.Spacer />
      <Button
        onClick={handleSubmit}
        loading={isSubmitting}
        className="py-3 w-full"
      >
        Log in
      </Button>
    </div>
  )
}

export default LoginPage