import { useRef } from 'react'
import { useModal } from '@ebay/nice-modal-react'

const ModalContainer = ({ children }) => {
  const containerRef = useRef(null)
  const modal = useModal()

  const handleOnClick = (e) => {
    if (e.target !== containerRef.current) return
    modal.remove()
  }

  return (
    <div
      className="flex fixed inset-0 z-50 justify-center items-center p-20"
      style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
      ref={containerRef}
      onClick={handleOnClick}
    >
      {children}
    </div>
  )
}

export default ModalContainer