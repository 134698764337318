import clsx from 'clsx'
import s from './Grid.module.scss'

export const Grid = ({ children, gap = 12, cols = 24, ...props }) => {
  return (
    <div
      className={clsx(s.grid, props.className)}
      style={{
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gap: `${gap}px`
      }}
    >
      {children}
    </div>
  )
}

export const Col = ({ children, sm, md, lg, xl, xxl, offset }) => {
  return (
    <div
      className={clsx(
        s.col,
        sm && s['sm-col-' + sm],
        md && s['md-col-' + md],
        lg && s['lg-col-' + lg],
        xl && s['xl-col-' + xl],
        xxl && s['xxl-col-' + xxl],
      )}
      style={{
        gridColumnStart: offset ?? 'auto'
      }}
    >
      {children}
    </div>
  )
}