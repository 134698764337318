import { EyeIcon, TruckIcon } from '@heroicons/react/24/solid'
import Map from 'components/Map'
import { Button, Col, Grid, Table } from 'components/gsys-ui'
import { useState } from 'react'
import { useLocHistory, useLocHistoryRuns } from 'util/queries'

const Watch = () => {
  const { isLoading, isError, error, data } = useLocHistoryRuns()
  const [selectedRun, setSelectedRun] = useState(null)

  if (!data) return null

  return (
    <Grid gap={7} className="h-full">
      <Col sm={10} className="overflow-y-scroll h-full">
        <div className="h-full">
          <Table
            cols={[
              {
                header: '',
                accessorKey: 'DateTime',
                cellSize: 1,
              },
              {
                header: '',
                accessorKey: 'RunId',
              },
              {
                header: '',
                accessorKey: 'User',
              },
              {
                header: '',
                accessorKey: 'Count',
              },
              {
                header: '',
                accessorKey: 'warn',
                cellSize: 1,
                cellPad: false,
                cell: ({ row }) => (
                  <Button className="flex items-center" onClick={() => setSelectedRun(row.original.RunId)}>
                    <EyeIcon className="mx-1 w-5 h-5 text-red-500" />
                  </Button>
                )
              }
            ]}
            data={data.slice(0, 30)}
            isPaginated={false}
          />
        </div>
      </Col>
      <Col sm={14}>
        {
          selectedRun && (
            <MapCard selectedRun={selectedRun} />
          )
        }
      </Col>
    </Grid>
  )
}

const MapCard = ({ selectedRun }) => {
  const { isLoading, isError, error, data } = useLocHistory(selectedRun)

  const points = data ? data.map((el) => el.Location[0]) : []

  return (
    <Map
      points={points}
    >
      {
        data && data.map((el, ind) => (
          <div key={ind} lat={el.Location[0].lat} lng={el.Location[0].lon}>
            <TruckIcon className="w-7 h-7 text-red-500" />
          </div>
        ))
      }
    </Map>
  )
}

export default Watch