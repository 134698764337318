import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { MagnifyingGlassIcon, ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline'

import useDebounce from 'hooks/useDebounce'
import { Card, Button, Dropdown, Table, Spinner, ButtonInput } from 'components/gsys-ui'
import { useSearchManifest } from 'util/queries'
import RegPlate from 'components/RegPlate'
import moment from 'moment'
import { getTimeDifference } from 'util/helpers'

const SearchField = ({ setRunId }) => {
  const [searchField, setSearchField] = useState('')
  const searchDebounced = useDebounce(searchField, 200)
  const [isOpen, setIsOpen] = useState(false)

  const { isLoading, isError, data: res, error } = useSearchManifest(
    searchDebounced,
    { enabled: searchDebounced.length >= 3 }
  )

  useEffect(() => {
    if (isOpen === false) {
      if (isLoading === false && res && searchField.length >= 3) {
        setIsOpen(true)
      }
    } else {
      if (searchField.length < 3) {
        setIsOpen(false)
      }
    }
  }, [searchField, isLoading, res])

  const handleCancel = () => {
    setIsOpen(false)
    //setSearchField('')
  }

  const data = res

  return (
    <div className="relative w-full">
      <ButtonInput
        placeholder="Type to search for a completed run..."
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
        onClick={() => setIsOpen(true)}
      >
        {
          isLoading ? (
            <Spinner size={20} color="rgb(255,255,255)" />
          ) : (
            <MagnifyingGlassIcon className="w-5 h-5" />
          )
        }
      </ButtonInput>

      {
        isOpen && (
          <ResultsDrawer
            setRunId={setRunId}
            data={data ? data.sort((a, b) => moment(a.headerDetails[0].StartDate).isBefore(moment(b.headerDetails[0].StartDate))) : []}
            closeDrawer={handleCancel}
          />
        )
      }
    </div>
  )
}

const ResultsDrawer = ({ setRunId, data, closeDrawer }) => {
  const [page, setPage] = useState(0)
  const maxPage = Math.floor(data.length / 5)
  const paginatedData = data.slice(page * 5, (page * 5) + 5)

  const prevPage = () => {
    if (page > 0) setPage(page - 1)
  }

  const nextPage = () => {
    if (page + 1 < maxPage) setPage(page + 1)
  }

  const handleClick = (id) => {
    setRunId(id)
    closeDrawer()
  }

  return (
    <div className="absolute w-full bg-white rounded border shadow-lg border-grey-400 top-[100%] left-0 mt-1 flex flex-col space-y-3 p-4 z-50">
      <table className="mx-[-16px]">
        <thead>
          <Trh>
            <Th>Reg</Th>
            <Th>Drops</Th>
            <Th>Date</Th>
            <Th>Duration</Th>
            <Th className="w-[90px]"></Th>
          </Trh>
        </thead>
        <tbody>
          {
            paginatedData.map((row, ind) => (
              <Tr key={ind} onClick={() => handleClick(row.headerDetails[0].RunId)}>
                <Td><RegPlate size={0.7}>{row.headerDetails[0].Reg}</RegPlate></Td>
                <Td>{row.completedRuns.length - 1}</Td>
                <Td>
                  {moment(row.headerDetails[0].StartDate).format('ddd Do MMM HH:mm')}
                  {' - '}
                  {moment(row.completedRuns[row.completedRuns.length - 1].CompletedDate).format('HH:mm')}
                </Td>
                <Td>
                  {
                    getTimeDifference(
                      row.headerDetails[0].StartDate,
                      row.completedRuns && row.completedRuns.length > 0 ? (
                        row.completedRuns[row.completedRuns.length - 1].CompletedDate
                      ) : (
                        row.headerDetails[0].StartDate
                      )
                    )
                  }
                </Td>
                <Td>
                  <div className="flex justify-end items-center text-sm font-semibold">
                    <span>View</span> <ChevronRightIcon className="ml-2 w-5 h-5" />
                  </div>
                </Td>
              </Tr>
            ))
          }
        </tbody>
      </table>
      {
        maxPage > 0 ? (
          <PaginationControls
            prevPage={prevPage}
            nextPage={nextPage}
            page={page}
            maxPage={maxPage}
          />
        ) : null
      }
      <div className="flex justify-end">
        <Button onClick={closeDrawer}>
          <XMarkIcon className="mr-1 w-5 h-5" />
          Cancel
        </Button>
      </div>
    </div>
  )
}

const PaginationControls = ({ prevPage, nextPage, page, maxPage }) => (
  <div className="flex">
    <div className="flex flex-1 justify-start">
      <Button variant="white" onClick={prevPage}><ChevronLeftIcon className="w-5 h-5" /></Button>
    </div>
    <div className="flex flex-1 justify-center items-center text-sm font-semibold">
      Page {page + 1}/{maxPage}
    </div>
    <div className="flex flex-1 justify-end">
      <Button variant="white" onClick={nextPage}><ChevronRightIcon className="w-5 h-5" /></Button>
    </div>
  </div>
)

const Trh = ({ children, ...props }) => <tr {...props} className={clsx('font-semibold bg-gray-100 cursor-pointer select-none', props.className)}>{children}</tr>
const Tr = ({ children, ...props }) => <tr {...props} className={clsx('cursor-pointer select-none hover:bg-gray-50 border-b border-gray-100', props.className)}>{children}</tr>
const Td = ({ children, ...props }) => <td {...props} className={clsx('p-2', props.className)}>{children}</td>
const Th = ({ children, ...props }) => <td {...props} className={clsx('px-2 py-2', props.className)}>{children}</td>

export default SearchField