import clsx from 'clsx'

import { Input } from './Input'
import Button from '../Button/Button'

import s from './Input.module.scss'

const ButtonInput = ({ children, ...props }) => {
  return (
    <div className={s.buttonInputGroup}>
      <Input
        className={props.className}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        onFocus={props.onFocus}
      />
      <Button
        className={clsx(s.button)}
        variant={props.variant}
        onClick={props.onClick}
      >
        {children}
      </Button>
    </div>
  )
}

export default ButtonInput