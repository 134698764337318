import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ArrowUturnLeftIcon, CheckIcon, DocumentTextIcon, ListBulletIcon, SwatchIcon, XMarkIcon } from '@heroicons/react/24/outline'

import ModalContainer from 'components/ModalContainer'
import { Button, Card, Input, Table } from 'components/gsys-ui'
import useClickOutside from 'hooks/useClickOutside'
import { useEffect, useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useUpdateSetting } from 'util/mutations'
import { useSettings } from 'util/queries'

const def = [
  { mins: -20, colour: '#0ea5e9' },
  { mins: -10, colour: '#7dd3fc' },
  { mins: 0, colour: '#fb923c' },
  { mins: 5, colour: '#f87171' },
  { mins: 10, colour: '#dc2626' },
]

const SettingsModal = NiceModal.create(() => {
  const modal = useModal()
  const mutation = useUpdateSetting('assignmentTripColours')
  const [isSaveLoading, setSaveLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(false)
  const { isLoading, isError, error, data } = useSettings()
  const [settings, setSettings] = useState(null)

  useEffect(() => {
    if (!data || firstLoad) return
    const settingsKey = data.filter((el) => el.Key === 'assignmentTripColours').pop()
    const dataParsed = settingsKey ? JSON.parse(settingsKey.Value) : def
    setSettings(dataParsed)
    setFirstLoad(true)
  }, [data])

  const handleChange = (ind) => (mins, colour) => {
    console.log(mins, colour)
    let oldSettings = [...settings]
    oldSettings[ind] = { mins, colour }
    setSettings(oldSettings)
  }

  const handleSave = async () => {
    setSaveLoading(true)
    await mutation.mutateAsync(settings)
    modal.remove()
  }

  if (isLoading || !data) return null

  console.log(settings)

  return (
    <ModalContainer>
      <Card className="!w-auto">
        <Card.Title Icon={SwatchIcon}>
          Trip colour coding
        </Card.Title>
        <Card.Body>
          {
            settings && (
              settings.map((row, ind) => (
                <TimeColourInput key={ind} row={row} onChange={handleChange(ind)} />
              ))
            )
          }
          <div className="flex justify-end mt-4 space-x-2">
            <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Close</Button>
            <Button Icon={ArrowUturnLeftIcon} variant="white" onClick={() => setSettings(def)}>Reset to defaults</Button>
            <Button Icon={CheckIcon} variant="green" loading={isSaveLoading} onClick={handleSave}>Save</Button>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

const TimeColourInput = ({ row, onChange }) => {
  const [showPicker, setShowPicker] = useState(false)
  const popover = useRef()
  useClickOutside(popover, () => setShowPicker(false))

  return (
    <div className="flex items-end mb-1 space-x-2" >
      <div className="w-16">
        <Input label="Mins" value={row.mins} onChange={(e) => onChange(e.target.value, row.colour)} />
      </div>
      <Button className="relative" onClick={() => setShowPicker(true)}>
        Select colour
        {
          showPicker && (
            <div ref={popover} className="absolute top-[-100%] z-50 border border-gray-400 bg-white p-4 rounded-md">
              <HexColorPicker
                color={row.colour}
                onChange={(colour) => onChange(row.mins, colour)}
              />
            </div>
          )
        }
      </Button>
      <div className="w-10 h-10 rounded-md border border-gray-400" style={{ background: row.colour }} />
    </div>
  )
}

export default SettingsModal