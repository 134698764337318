import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import useSupercluster from 'use-supercluster'

import { Grid, Col, Table, Card, Spinner, Input, MultiButton, Badge, Button, BaseInput } from 'components/gsys-ui'
import { useCustomer, useCustomers, useCustomersPagedLoc, useCustomersPagedWithLoc, useCustomersWithLoc, useCustomersWithoutLocCount } from 'util/queries'
import Map from 'components/Map'
import MechanicIcon from 'components/MechanicIcon'
import { GlobalContext } from 'context/GlobalContext'
import { ArrowUturnLeftIcon, CheckIcon, ExclamationCircleIcon, MagnifyingGlassIcon, MapIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useUpdateCustomerLoc } from 'util/mutations'
import haxios from 'util/haxios'
import NiceModal from '@ebay/nice-modal-react'

const Customers = () => {
  const [showCust, setShowCust] = useState(null)
  const [pendingNewLoc, setPendingNewLoc] = useState(null)
  const [geoLoc, setGeoLoc] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const { branch } = useContext(GlobalContext)

  useEffect(() => {
    setShowCust(null)
  }, [branch])

  useEffect(() => {
    setPendingNewLoc(null)
    setIsEditing(false)
  }, [showCust])

  return (
    <Grid gap={7} className="p-2 h-full">
      <Col sm={6}>
        <CustomersCard setShowCust={setShowCust} />
      </Col>
      <Col sm={18}>
        <MapCard
          showCust={showCust}
          setShowCust={setShowCust}
          pendingNewLoc={pendingNewLoc}
          setPendingNewLoc={setPendingNewLoc}
          geoLoc={geoLoc}
          setGeoLoc={setGeoLoc}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        >
          {
            showCust && (
              <InfoCard
                acct={showCust}
                setShowCust={setShowCust}
                pendingNewLoc={pendingNewLoc}
                setPendingNewLoc={setPendingNewLoc}
                geoLoc={geoLoc}
                setGeoLoc={setGeoLoc}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            )
          }
        </MapCard>
      </Col>
    </Grid >
  )
}

const MapCard = ({ showCust, setShowCust, children, pendingNewLoc, setPendingNewLoc, isEditing, geoLoc, setGeoLoc }) => {
  const { isLoading, isError, error, data } = useCustomers()
  const custWithLocs = data ? data.filter((cust) => cust.Loc[0] !== null) : []

  const focusPoint = data && geoLoc ? geoLoc : showCust ? (
    (() => {
      const cust = data.filter((el) => el.KeyCode === showCust)
      if (cust.length) return cust.pop().Loc[0]
      return null
    })()
  ) : null

  const [mapBounds, setMapBounds] = useState({
    bounds: [0, 0, 0, 0],
    zoom: 0,
  })

  const custFilteredForShowCust = showCust ? (
    custWithLocs.filter((el) => el.KeyCode === showCust)
  ) : (
    custWithLocs
  )

  const points = custFilteredForShowCust.map((cust) => cust.Loc[0])
  const superclusterPoints = custFilteredForShowCust.map((cust) => ({
    type: 'Feature',
    properties: { cluster: false, acct: cust.Name, keycode: cust.KeyCode },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(cust.Loc[0].lon),
        parseFloat(cust.Loc[0].lat)
      ]
    }
  }))

  const { clusters, supercluster } = useSupercluster({
    points: superclusterPoints,
    bounds: mapBounds.bounds,
    zoom: mapBounds.zoom,
    options: {
      radius: 75,
      maxZoom: 20
    }
  })

  const onMapChange = ({ bounds, zoom }) => {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    setMapBounds({ ...mapBounds, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()], zoom })
  }

  const clusterCounts = clusters.map((el) => el.properties.point_count).filter(Boolean)

  const handleMapClick = (e) => {
    const latLng = e.latLng.toJSON()
    setPendingNewLoc({ lon: latLng.lng, lat: latLng.lat })
    setGeoLoc(null)
  }

  return (
    <div className="relative h-full">
      <Map
        points={points}
        onChange={onMapChange}
        focusPoint={focusPoint}
        setFocusPoint={setShowCust}
        noCenterPointsChange={true}
        events={[{
          name: 'click',
          handler: handleMapClick
        }]}
      >
        {
          clusters && clusters.map((el, ind) => (
            el.properties.cluster ? (
              <MechanicIcon
                key={el.id}
                lat={el.geometry.coordinates[1]}
                lng={el.geometry.coordinates[0]}
                count={el.properties.point_count}
                highest={Math.max(...clusterCounts)}
                lowest={Math.min(...clusterCounts)}
                onClick={() => NiceModal.show('customersShowClusterModal', {
                  keycodes: supercluster.getLeaves(el.id, Infinity).map((cust) => cust.properties.keycode),
                  viewCust: setShowCust
                })}
              />
            ) : (
              <MechanicIcon
                key={el.properties.acct}
                lat={el.geometry.coordinates[1]}
                lng={el.geometry.coordinates[0]}
                name={el.properties.acct}
                shadow={!!pendingNewLoc && !!isEditing}
                onClick={() => setShowCust(el.properties.keycode)}
              />
            )
          ))
        }
        {
          isEditing && pendingNewLoc && (
            <MechanicIcon
              key="pendingNewLoc"
              lat={pendingNewLoc.lat}
              lng={pendingNewLoc.lon}
              name={data.filter((el) => el.KeyCode === showCust).pop()?.Name}
              glow={true}
            />
          )
        }
      </Map>
      {children}
    </div>
  )
}

const InfoCard = ({ acct, setShowCust, pendingNewLoc, setPendingNewLoc, isEditing, setIsEditing, geoLoc, setGeoLoc }) => {
  const { isLoading, isError, error, data } = useCustomer(acct)
  const [isSaving, setSaving] = useState(false)
  const [isGeoLoading, setGeoLoading] = useState(false)
  const [addrInput, setAddrInput] = useState('')
  const [pcodeInput, setPcodeInput] = useState('')
  const mutation = useUpdateCustomerLoc(acct)
  const inputsChanged = isEditing && (
    (data?.[0]?.Addra && addrInput !== data[0].Addra) ||
    (data?.[0]?.Pcode && pcodeInput !== data[0].Pcode)
  )

  const handleCancel = () => {
    setIsEditing(false)
    setPendingNewLoc(null)
  }

  const handleBack = () => {
    setShowCust(null)
    setIsEditing(false)
    setPendingNewLoc(null)
  }

  const handleSave = async () => {
    setSaving(true)
    await mutation.mutateAsync({ loc: [pendingNewLoc], addra: addrInput, pcode: pcodeInput })
    setSaving(false)
    setPendingNewLoc(null)
    setIsEditing(false)
  }

  const handleEdit = () => {
    setPendingNewLoc(null)
    setIsEditing(true)
    setAddrInput(data?.[0]?.Addra || '')
    setPcodeInput(data?.[0]?.PCode || '')
  }

  const handleLocate = async () => {
    try {
      setGeoLoading(true)
      const geoString = encodeURIComponent(`${addrInput}, ${pcodeInput}`)
      const res = await haxios.get(`/dd/geocode?addr=${geoString}`)
      setGeoLoading(false)
      setPendingNewLoc(res)
      setGeoLoc(res)
    } catch (e) {
      console.log(e)
      setGeoLoading(false)
    }
  }

  if (isLoading) return null
  if (!data?.[0]) return null

  return (
    <div className="absolute top-4 left-4 w-[450px]">
      <Card>
        <div className="p-2">
          <div className="flex justify-between items-center w-full">
            <div className="text-xl font-bold">{data[0].Name}</div>
          </div>
          <div className="">{data[0].KeyCode}</div>
          <div className="pt-1 pb-2 space-x-2">
            <a
              href={`https://www.google.co.uk/search?q=${encodeURIComponent(`${data[0].Name}, ${data[0].Addra}, ${data[0].PCode}`)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button tiny variant="white"><MagnifyingGlassIcon className="mr-1 w-4 h-4" />Google search</Button>
            </a>

            <a
              href={`https://www.google.co.uk/maps/search/${encodeURIComponent(`${data[0].Name}, ${data[0].Addra}, ${data[0].PCode}`)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button tiny variant="white"><MapIcon className="mr-1 w-4 h-4" />Google maps</Button>
            </a>
          </div>
          <div className="font-bold">Address on file</div>
          {
            isEditing ? (
              <div className="space-y-1">
                <BaseInput placeholder="Address" value={addrInput} onChange={(e) => setAddrInput(e.target.value)} />
                <BaseInput placeholder="Postcode" value={pcodeInput} onChange={(e) => setPcodeInput(e.target.value)} />
              </div>
            ) : (
              <>
                {
                  data[0].Addra ? (
                    <div>{data[0].Addra}</div>
                  ) : (
                    <div className="flex items-center"><ExclamationCircleIcon className="mr-1 w-5 h-5 text-red-500" /> No address</div>
                  )
                }
                {
                  data[0].PCode ? (
                    <div>{data[0].PCode}</div>
                  ) : (
                    <div className="flex items-center"><ExclamationCircleIcon className="mr-1 w-5 h-5 text-red-500" /> No postcode</div>
                  )
                }
              </>
            )
          }
        </div>
        {
          isEditing ? (
            <div className="p-2">
              <div className="pb-1 text-center"><div className="text-red-600">{(pendingNewLoc || inputsChanged) && 'Your changes have not been saved.'}</div></div>
              <div className="flex justify-between items-center w-full">
                <Button onClick={handleLocate}><MagnifyingGlassIcon className="mr-1 w-5 h-5" />Locate address</Button>
                <div className="flex space-x-2">
                  <Button variant="white" onClick={handleCancel}><XMarkIcon className="mr-1 w-5 h-5" />Cancel</Button>
                  <Button variant="green" onClick={handleSave} loading={isSaving}><CheckIcon className="mr-1 w-5 h-5" />Save</Button>
                </div>
              </div>
            </div>
          ) : (

            <div className="p-2">
              <div className="flex justify-end items-center space-x-2 w-full">
                <Button onClick={handleEdit}><PencilIcon className="mr-1 w-5 h-5" />Edit loc</Button>
                <Button onClick={handleBack}><ArrowUturnLeftIcon className="mr-1 w-5 h-5" />Back</Button>
              </div>
            </div>
          )
        }
      </Card >
    </div >
  )
}

const WithoutLocButton = () => {
  const { isLoading, isError, error, data } = useCustomersWithoutLocCount()

  return (
    <span>
      No loc
      {
        data && data > 0 ? (
          <span className="pl-1">
            <Badge variant="red" rounded>{data}</Badge>
          </span>
        ) : null
      }
    </span>
  )
}

const locMultiOpt = [
  {
    label: 'Loc',
    value: 'withloc'
  },
  {
    label: <WithoutLocButton />,
    value: 'noloc'
  }
]

const CustomersCard = ({ setShowCust }) => {
  const [{ pageIndex }, setPagination] = useState({ pageIndex: 0, pageSize: null })
  const tableContainerRef = useRef(null)
  const [pageSize, setPageSize] = useState(null)
  const [name, setName] = useState('')
  const [locMulti, setLocMulti] = useState('withloc')
  const { isLoading, isError, error, data } = useCustomersPagedLoc({ pageIndex, pageSize, name, withLoc: locMulti }, { keepPreviousData: true, enabled: !!pageSize })

  useEffect(() => {
    if (!tableContainerRef.current) return
    setPageSize(Math.floor((tableContainerRef.current.offsetHeight - 79) / 33))
  }, [tableContainerRef])

  const handleName = (v) => {
    setPagination({ pageIndex: 0, pageSize: null })
    setName(v)
  }

  return (
    <Card className="h-full">
      <div className="flex flex-col h-full">
        <div className="flex flex-none m-2 space-x-2">
          <div className="flex justify-between items-center w-full">
            <div className="w-[200px]">
              <Input
                placeholder="Type to search..."
                value={name}
                onChange={(e) => handleName(e.target.value)}
              />
            </div>
            <MultiButton
              value={locMulti}
              options={locMultiOpt}
              onChange={setLocMulti}
            />
          </div>
        </div>
        <div className="flex-1" ref={tableContainerRef}>
          {
            pageSize && data && (
              <Table
                isPaginated={true}
                currentPage={pageIndex}
                onPageChange={setPagination}
                totalRowsCount={data ? data.pagination.totalDocs : 0}
                pageRowCount={pageSize}
                onRowClick={(row) => setShowCust(row.KeyCode)}
                padToPageSize={true}
                dummyRowHeight={32}
                data={data ? data.response : []}
                columns={[
                  {
                    header: 'ACCT',
                    accessorKey: 'KeyCode',
                    cellSize: 110,
                  },
                  {
                    header: 'NAME',
                    accessorKey: 'Name',
                    truncate: true
                  },
                  {
                    header: '',
                    accessorKey: 'warn',
                    cellSize: 1,
                    cellPad: false,
                    cell: ({ row }) => row.original.Loc[0] ? null : (
                      <div className="flex items-center">
                        <ExclamationCircleIcon className="mx-1 w-5 h-5 text-red-500" />
                      </div>
                    )
                  }
                ]}
              />
            )
          }
        </div>
      </div>
    </Card>
  )
}

export default Customers