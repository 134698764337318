import moment from 'moment'
import RegPlate from './RegPlate'

const VanIcon = ({ reg, returnTime }) => {
  return (
    <div className="flex relative flex-col items-center">

      <img src='/img/van.png' className="h-10" />
      <RegPlate size={0.6}>{reg}</RegPlate>
      {
        returnTime && (
          <div className="flex items-center px-1.5 py-1 mt-1 whitespace-nowrap bg-white rounded-full border border-gray-300 shadow-sm">
            <img src="/img/finish.png" className="mr-1 w-5 h-5" />
            <div className="font-bold">ETA {moment(returnTime).format('HH:mm')}</div>
          </div>
        )
      }
    </div>
  )
}

export default VanIcon