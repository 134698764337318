import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useState } from 'react'
import { CheckIcon, NewspaperIcon, PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { v4 as uuidv4 } from 'uuid'

import ModalContainer from 'components/ModalContainer'
import { Button, Card, Checkbox, Form } from 'components/gsys-ui'
import { useAddBulletin, useSendBulletin } from 'util/mutations'
import { bulletinValidation } from 'util/validations'
import BulletinForm from './BulletinForm'
import { useBulletinCustomers } from 'util/queries'
import { sortByKeyString } from 'util/helpers'


const SendBulletinModal = NiceModal.create(({ bulletinId }) => {
  const modal = useModal()
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const [selected, setSelected] = useState([])
  const { isLoading, isError, error, data } = useBulletinCustomers()
  const mutation = useSendBulletin(bulletinId)

  const addSelected = (id) => {
    const newSelected = Array.isArray(id) ? (
      [...selected, ...id]
    ) : (
      [...selected, id]
    )

    setSelected(newSelected)
  }

  const removeSelected = (id) => {
    const newSelected = Array.isArray(id) ? (
      selected.filter((el) => !id.includes(el))
    ) : (
      selected.filter((el) => el !== id)
    )

    setSelected(newSelected)
  }

  const areAllSelected = (arr) => {
    return arr.every((id) => selected.includes(id))
  }

  const handleSelect = (id) => {
    if (Array.isArray(id)) {
      if (areAllSelected(id)) {
        removeSelected(id)
      } else {
        addSelected(id)
      }
    } else {
      if (selected.includes(id)) {
        removeSelected(id)
      } else {
        addSelected(id)
      }
    }
  }

  if (isLoading) return null

  const dataGrouped = data.reduce((acc, cur) => {
    if (!acc[cur.Branch]) acc[cur.Branch] = {}
    if (!acc[cur.Branch][cur.Acct]) acc[cur.Branch][cur.Acct] = []
    acc[cur.Branch][cur.Acct].push(cur)
    return acc
  }, {})

  const handleSubmit = async (res) => {
    setSubmitting(true)
    await mutation.mutateAsync(selected)
    modal.remove()
  }

  return (
    <ModalContainer>
      <Card className="!w-[600px]">
        <Card.Title Icon={PaperAirplaneIcon}>Send a bulletin</Card.Title>
        <Card.Body className="!h-[640px] flex flex-col">
          <div className="overflow-y-scroll flex-1 pr-1">
            {
              Object.keys(dataGrouped).map((branch, ind) => {
                const branchIds = Object.keys(dataGrouped[branch]).map((acct) => dataGrouped[branch][acct].map((el) => el._id)).flat()

                return (
                  <div key={ind} className="mt-2">
                    <div className="flex justify-between items-center p-2 text-xl font-bold rounded-md border select-none border-neutral-400">
                      <div>{branch === 'undefined' ? 'BRANCH UNASSIGNED' : branch}</div>
                      <Checkbox
                        checked={areAllSelected(branchIds)}
                        onClick={() => handleSelect(branchIds)}
                      />
                    </div>
                    <div>
                      {
                        Object.keys(dataGrouped[branch]).sort().map((acct, ind) => {
                          return (
                            <div key={ind} className="py-2">
                              <div className="flex justify-between items-center font-bold border-b select-none border-b-1 border-neutral-400">
                                <div>{acct} - {dataGrouped[branch][acct][0].Name}</div>
                                <Checkbox
                                  checked={areAllSelected(dataGrouped[branch][acct].map((el) => el._id))}
                                  onClick={() => handleSelect(dataGrouped[branch][acct].map((el) => el._id))}
                                />
                              </div>
                              <div>
                                {
                                  dataGrouped[branch][acct].sort(sortByKeyString('User')).map((cust, ind) => (
                                    <div
                                      onClick={() => handleSelect(cust._id)}
                                      className="flex items-center p-1 cursor-pointer select-none hover:bg-neutral-100"
                                    >
                                      <Checkbox checked={selected.includes(cust._id)} />
                                      <div className="ml-1">{cust.User}</div>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="flex flex-none justify-end pt-2 space-x-2">
            <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Cancel</Button>
            <Button Icon={CheckIcon} onClick={handleSubmit} loading={isSubmitting} variant="green">Submit</Button>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default SendBulletinModal