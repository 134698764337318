import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useState } from 'react'
import { NewspaperIcon } from '@heroicons/react/24/outline'
import { v4 as uuidv4 } from 'uuid'

import ModalContainer from 'components/ModalContainer'
import { Card, Form } from 'components/gsys-ui'
import { useUpdateBulletin } from 'util/mutations'
import { bulletinValidation } from 'util/validations'
import BulletinForm from './BulletinForm'


const UpdateBulletinModal = NiceModal.create(({ initial, afterSubmit = () => { } }) => {
  const modal = useModal()
  const mutation = useUpdateBulletin()

  const handleSubmit = async (res) => {
    modal.remove()
    afterSubmit()
  }

  return (
    <ModalContainer>
      <Card className="!w-[1200px]">
        <Card.Title Icon={NewspaperIcon}>Update a bulletin</Card.Title>
        <Card.Body className="!h-[640px]">
          <Form
            validation={bulletinValidation}
            mutation={mutation}
            FormComp={BulletinForm}
            onSubmit={handleSubmit}
            initial={initial}
          />
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default UpdateBulletinModal