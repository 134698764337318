import NiceModal from '@ebay/nice-modal-react'
import { ClockIcon, MagnifyingGlassIcon, PencilIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Avatar } from 'antd'
import { Card, Button, DateInput, MultiButton, Dropdown, Input, ButtonInput } from 'components/gsys-ui'
import Table, { PaginatedTable } from 'components/gsys-ui/Table/Table'
import moment from 'moment'
import { useState } from 'react'
import { groupByKey } from 'util/helpers'
import { useClocklog } from 'util/queries/tma'
import { BsDash } from 'react-icons/bs'
import clsx from 'clsx'

const ShiftsPage = () => {
  const data = [
    {
      Name: 'Billy Buttstuff',
      Branch: 'BHAM',
      Position: 'Accounts',
      Dob: new Date(),
      SchedulePattern: 'Standard40h',
      Schedule: [
        {
          Day: 1,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 2,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:10:00 GMT')
        },
        {
          Day: 3,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:20:00 GMT')
        },
        {
          Day: 6,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:30:00 GMT')
        },
        {
          Day: 7,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:40:00 GMT')
        }
      ]
    },
    {
      Name: 'Scroobius Pip',
      Branch: 'BHAM',
      Position: 'Accounts',
      Dob: new Date(),
      SchedulePattern: null,
      Schedule: [
        {
          Day: 1,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 2,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 3,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 4,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 5,
          Start: Date.parse('01 Jan 1970 06:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        }
      ]
    },
    {
      Name: 'Jenny',
      Branch: 'PETERSFIELD',
      Position: 'Sales',
      Dob: new Date(),
      SchedulePattern: 'PartTime',
      Schedule: [
        {
          Day: 1,
          Start: Date.parse('01 Jan 1970 12:00:00 GMT'),
          End: Date.parse('01 Jan 1970 17:00:00 GMT')
        },
        {
          Day: 3,
          Start: Date.parse('01 Jan 1970 12:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        },
        {
          Day: 4,
          Start: Date.parse('01 Jan 1970 12:00:00 GMT'),
          End: Date.parse('01 Jan 1970 15:00:00 GMT')
        }
      ]
    }
  ]

  const dataGrouped = groupByKey('Position', data)

  return (
    <Card>
      <Card.Title bordered Icon={ClockIcon}>
        <div>SHIFTS</div>
        <div className="w-64">
          <ButtonInput placeholder="Search for an employee...">
            <MagnifyingGlassIcon className="w-5 h-5" />
          </ButtonInput>
        </div>
      </Card.Title>
      {
        Object.keys(dataGrouped).map((key) => (
          <div className="border-b-[2px] last:border-b-0 border-b-gray-200 mb-2 last:mb-0">
            <Card.Title bordered Icon={BsDash}>
              <div>{key.toUpperCase()}</div>
            </Card.Title>
            <StaffTable data={dataGrouped?.[key] || []} />
          </div>
        ))
      }
    </Card>
  )
}

const StaffTable = ({ data, isLoading }) => {
  return (
    <Table
      data={data}
      isLoading={isLoading}
      tableFixed={true}
      rowHighlight={false}
      columns={[
        {
          header: 'EMPLOYEE',
          id: 'employee',
          cell: ({ row }) => <Employee data={row.original} />
        },
        {
          header: 'MON',
          id: 'mon',
          noPadAnywhere: true,
          cell: ({ row }) => <DayCell data={row.original.Schedule} day={1} isEditable={!row.original.SchedulePattern} />
        },
        {
          header: 'TUE',
          id: 'mon',
          noPadAnywhere: true,
          cell: ({ row }) => <DayCell data={row.original.Schedule} day={2} isEditable={!row.original.SchedulePattern} />
        },
        {
          header: 'WED',
          id: 'mon',
          noPadAnywhere: true,
          cell: ({ row }) => <DayCell data={row.original.Schedule} day={3} isEditable={!row.original.SchedulePattern} />
        },
        {
          header: 'THU',
          id: 'mon',
          noPadAnywhere: true,
          cell: ({ row }) => <DayCell data={row.original.Schedule} day={4} isEditable={!row.original.SchedulePattern} />
        },
        {
          header: 'FRI',
          id: 'mon',
          noPadAnywhere: true,
          cell: ({ row }) => <DayCell data={row.original.Schedule} day={5} isEditable={!row.original.SchedulePattern} />
        },
        {
          header: 'SAT',
          id: 'mon',
          noPadAnywhere: true,
          cell: ({ row }) => <DayCell data={row.original.Schedule} day={6} isEditable={!row.original.SchedulePattern} />
        },
        {
          header: 'SUN',
          id: 'mon',
          noPadAnywhere: true,
          cell: ({ row }) => <DayCell data={row.original.Schedule} day={7} isEditable={!row.original.SchedulePattern} />
        }
      ]}
    />
  )
}

const Employee = ({ data }) => {
  return (
    <div className="flex items-center">
      <div className="flex-none mr-2"><Avatar /></div>
      <div className="flex-1 space-y-1 w-full">
        <div className="font-bold text-md">{data.Name}</div>
        <div>
          {
            data.SchedulePattern && (
              <div className="inline-block px-1 py-0.5 text-xs bg-blue-100 rounded-full border border-blue-600 border-1">
                {data.SchedulePattern}
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

const DayCell = ({ data, day, isEditable }) => {
  const dayData = data.filter((el) => el.Day === day).pop()

  const Cont = ({ children, className }) => (
    <div className={clsx("relative h-20 border-l border-l-gray-200", className)}>
      <div className="flex absolute inset-0 items-center p-2">
        {children}
      </div>
    </div>
  )

  if (dayData) {
    return (
      <Cont>
        <div className="inline-flex items-center space-x-2">
          <div className="inline-flex items-center p-2 bg-blue-100 rounded-full border border-blue-600 border-1">
            {moment(dayData.Start).format('HH:mm')} - {moment(dayData.End).format('HH:mm')}
          </div>
          {
            isEditable && (
              <Button variant="white" small rounded><PencilSquareIcon className="w-4 h-4" /></Button>
            )
          }
        </div>
      </Cont>
    )
  }

  if (isEditable) return (
    <Cont className="bg-gray-50">
      <Button variant="white" small rounded Icon={PlusIcon} />
    </Cont>
  )

  return <Cont className="bg-gray-50"></Cont>
}


export default ShiftsPage