import haxios from './haxios'
import { blobToBase64 } from './helpers'

const BASE_URL = process.env.REACT_APP_BASE_URL

export const getApirAddr = async (companyId) => {
  const res = await haxios.get(`${process.env.REACT_APP_AUTOMMAPIR_URL}/api/getCompany/${companyId}`)
  return res[0]
}

export const getCompanyInfo = async () => {
  const res = await haxios.get(BASE_URL + '/api/user')
  return res
}

export const getImageFromBlobId = async (blobId) => {
  const res = await haxios.get(`${BASE_URL}/api/image/${blobId}`, { responseType: 'blob' })
  const blob = res
  const base64 = await blobToBase64(blob)
  return base64
}