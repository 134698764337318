import NiceModal from '@ebay/nice-modal-react'
import { ListBulletIcon, MapIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { Button, Card, Col, DateInput, Dropdown, Grid, Input, Spinner, Table } from 'components/gsys-ui'
import { PaginatedTable } from 'components/gsys-ui/Table/Table'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { usePodCustomers, usePodDrivers, usePods, useReturnCustomers, useReturns } from 'util/queries'

const Returns = () => {
  const [{ pageIndex }, setPagination] = useState({ pageIndex: 0, pageSize: null })
  const tableContainerRef = useRef(null)
  const [pageSize, setPageSize] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const { isLoading, isError, error, data } = useReturns({ pageIndex, pageSize, acct: selectedCustomer?.value }, { keepPreviousData: true, enabled: !!pageSize })

  useEffect(() => {
    if (!tableContainerRef.current) return
    setPageSize(Math.floor((tableContainerRef.current.offsetHeight - 79) / 28))
  }, [tableContainerRef])

  const handleCustomer = (v) => {
    setPagination({ pageIndex: 0, pageSize: null })
    setSelectedCustomer(v)
  }

  return (
    <Grid gap={7} className="p-2 h-full">
      <Col sm={24}>
        <Card className="h-full">
          <div className="flex flex-col h-full">
            <div className="flex flex-none m-2 space-x-2">
              <div className="w-[300px]">
                <CustomerDropdown
                  value={selectedCustomer}
                  onChange={handleCustomer}
                />
              </div>
            </div>
            <div className="flex-1" ref={tableContainerRef}>
              {
                pageSize && data && (
                  <Table
                    isPaginated={true}
                    currentPage={pageIndex}
                    onPageChange={setPagination}
                    totalRowsCount={data ? data.pagination.totalDocs : 0}
                    pageRowCount={pageSize}
                    padToPageSize={true}
                    dummyRowHeight={27}
                    onRowClick={(row) => NiceModal.show('returnsViewLinesModal', { lines: row.Parts })}
                    thinRows
                    data={data ? data.response : []}
                    columns={[
                      {
                        header: 'DATE',
                        accessorKey: 'Date',
                        cellSize: 120,
                        cell: ({ row }) => moment(row.original.Date).format('DD/MM/YY HH:mm')
                      },
                      {
                        header: 'ACCT',
                        accessorKey: 'Acct',
                        cellSize: 20
                      },
                      {
                        header: 'NAME',
                        accessorKey: 'Name',
                        truncate: true,
                        cellSize: 250
                      },
                      {
                        header: 'DRIVER',
                        accessorKey: 'User',
                        cellSize: 100,
                      },
                      {
                        header: '',
                        accessorKey: 'parts',
                        cellSize: 1,
                        disableSortBy: true,
                        cellPad: false,
                        cell: ({ row }) => (
                          <div className="flex justify-end">
                            <Button
                              variant="rounded"
                              tiny
                              onClick={() => NiceModal.show('returnsViewLinesModal', { lines: row.original.Parts })}
                              Icon={ListBulletIcon}
                            />
                          </div>
                        )
                      }
                    ]}
                  />
                )
              }
            </div>
          </div>
        </Card>
      </Col>
    </Grid>
  )
}

const CustomerDropdown = ({ value, onChange }) => {
  const { isLoading, isError, error, data } = useReturnCustomers()

  return (
    <Dropdown
      placeholder="Select customer..."
      value={value}
      onChange={onChange}
      options={data || []}
      isClearable={true}
    />
  )
}

export default Returns