import { useContext } from 'react'
import { useQuery } from 'react-query'

import haxios from 'util/haxios'
import { GlobalContext } from 'context/GlobalContext'
import moment from 'moment'

export const useClocklog = (from, to, branch = null, opt = {}) => useQuery({
  queryKey: ['tma', 'clocklog', moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD'), branch],
  queryFn: () => haxios.post(`${process.env.REACT_APP_BASE_URL}/api/tma/clocklogs`, {
    Branch: branch,
    StartDate: moment(from).format('YYYY-MM-DD'),
    EndDate: moment(to).format('YYYY-MM-DD')
  }),
  ...opt
})

export const useHolidays = (from, to, position = null, branch = null, opt = {}) => useQuery({
  queryKey: ['tma', 'holidays', moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD'), position, branch],
  queryFn: () => haxios.post(`${process.env.REACT_APP_BASE_URL}/api/tma/holidays`, {
    Branch: branch,
    StaffPosition: position,
    StartDate: moment(from).format('YYYY-MM-DD'),
    EndDate: moment(to).format('YYYY-MM-DD')
  }),
  ...opt
})

export const useSickDays = (from, to, position = null, branch = null, opt = {}) => useQuery({
  queryKey: ['tma', 'sickdays', moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD'), position, branch],
  queryFn: () => haxios.post(`${process.env.REACT_APP_BASE_URL}/api/tma/sickdays`, {
    Branch: branch,
    StaffPosition: position,
    StartDate: moment(from).format('YYYY-MM-DD'),
    EndDate: moment(to).format('YYYY-MM-DD')
  }),
  ...opt
})

export const useStaff = (branch = null, opt = {}) => useQuery({
  queryKey: ['tma', 'staff', branch],
  queryFn: () => haxios.get(`${process.env.REACT_APP_BASE_URL}/api/tma/staff`),
  ...opt
})