import clsx from 'clsx'

import s from './MechanicIcon.module.scss'

const MechanicIcon = ({ count, lowest, highest, name, onClick, shadow, glow, clickData }) => {
  if (count) {
    const ratio = (count - lowest) / (highest - lowest)
    const ratioRounded = isNaN(ratio) ? 1 : Math.round(ratio * 2 * 10) / 10
    const size = `${2 + ratioRounded}rem`

    return (
      <div className="relative z-50" onClick={onClick}>
        <div
          className="flex absolute justify-center items-center text-lg font-bold text-white bg-clip-padding bg-orange-400 rounded-full border-4 border-orange-400/60"
          style={{ width: size, height: size }}
        >
          {count}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center absolute translate-x-[-50%] translate-y-[-100%]" onClick={onClick}>
      <img src="/img/mechanic.png" className={clsx('w-10 h-10 mb-0.5 drop-shadow-md mr-[-8px]', s.icon, glow && s.glow, shadow && s.shadow)} />
      {
        name && (
          <div className={clsx(
            'w-full bg-white rounded-md border border-gray-400 p-1 font-bold drop-shadow-md whitespace-nowrap text-[10px]',
            s.bubble,
            glow && s.glow,
            shadow && s.shadow
          )}>
            {name}
          </div>
        )
      }
    </div>

  )
}

export default MechanicIcon