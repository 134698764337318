import haxios from 'util/haxios'
import { useEffect, useState } from 'react'

const useAuth = () => {
  const [isLoading, setLoading] = useState(true)
  const [isAuthed, setAuthed] = useState(null)

  useEffect(() => {
    (async () => {
      const res = await checkAuth()
      setAuthed(res)
      setLoading(false)
    })()
  }, [])

  const checkAuth = async () => {
    try {
      const res = await haxios.get(process.env.REACT_APP_BASE_URL + '/api/authenticate/expired')
      if (res.error) return false
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  return { isAuthed, authLoading: isLoading }
}

export default useAuth