
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel } from '@tanstack/react-table'
import { ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon, ArrowUpRightIcon, ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Button, Spinner } from 'components/gsys-ui'
import { useMemo, useState } from 'react'
import { Link } from '@tanstack/react-router'

const ReactTable = ({
  data,
  cols,
  isPaginated,
  isCompact,
  notFull = false,
  currentPage,
  onPageChange,
  onRowClick = () => { },
  totalRowsCount,
  padToPageSize,
  emptyMessage,
  thinRows,
  isLoading,
  tableFixed = false,
  pageRowCount = 10,
  dummyRowHeight,
  rowHighlight = true,
  showPaginationControls = true,
  columnVisibility = {}
}) => {
  const table = useReactTable({
    data,
    columns: cols,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: !!isPaginated,
    pageCount: isPaginated ? Math.ceil(totalRowsCount / pageRowCount) : -1,
    onPaginationChange: !!isPaginated && onPageChange,
    state: {
      pagination: !!isPaginated && { pageIndex: currentPage, pageSize: pageRowCount },
      columnVisibility
    }
  })

  return (
    <div className={clsx(notFull && 'overflow-x-scroll h-full')}>
      <table className={clsx(!notFull && 'w-full', notFull && 'min-w-full', tableFixed && 'table-fixed')}>
        <thead>
          {
            table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {
                  headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      className={clsx(
                        'border-b-2 border-b-grey-400 text-sm font-bold text-left',
                        header.column.columnDef.headerPad !== false && !isCompact && 'py-1.5 px-2',
                        isCompact && 'py-0.5 px-0',
                        header.column.columnDef.noPadAnywhere === true && '!p-0'
                      )}
                    >
                      {
                        header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                      }
                    </th>
                  ))}
              </tr>
            ))}
        </thead>
        <tbody className="relative">
          {
            table.getRowModel().rows.length !== 0 && table.getRowModel().rows.map(row => (
              <tr key={row.id} className={clsx(rowHighlight && 'hover:bg-blue-50')} onClick={() => onRowClick(row.original)}>
                {
                  row.getVisibleCells().map(cell => {
                    const text = flexRender(cell.column.columnDef.cell, cell.getContext())

                    return (
                      <td
                        key={cell.id}
                        className={clsx(
                          'border-t border-t-grey-400 relative text-sm',
                          cell.column.columnDef.cellPad !== false && !thinRows && 'py-1.5 px-2',
                          cell.column.columnDef.cellPad !== false && thinRows && 'py-0.5 px-2',
                          cell.column.columnDef.noPadAnywhere === true && '!p-0',
                          cell.column.columnDef.noWrap && 'whitespace-nowrap'
                        )}
                        style={{
                          width: cell.column.columnDef.cellSize ? `${cell.column.columnDef.cellSize}px` : undefined,
                        }}
                      >
                        {
                          cell.column.columnDef.truncate ? (
                            <span className="absolute inset-0 leading-8 truncate" title={cell.getValue()}>{text}</span>
                          ) : text
                        }
                      </td>
                    )
                  })}
              </tr>
            ))
          }
          {
            isPaginated && padToPageSize &&
            table.getRowModel().rows.length < pageRowCount &&
            (new Array(pageRowCount - table.getRowModel().rows.length).fill('ayylmao')).map((_, ind) => (
              <tr key={ind} className={clsx('select-none', ind === 0 && 'border-t-grey-400 border-t-2')}>
                {
                  table.getHeaderGroups().map((headerGroup) => headerGroup.headers.map((header) => (
                    <td key={header.id} className="" style={{ height: `${dummyRowHeight}px` }}>&nbsp;</td>
                  )))
                }
              </tr>
            ))
          }
          {
            isLoading && (
              <div className="flex absolute inset-0 justify-center items-center bg-white">
                <Spinner />
              </div>
            )
          }
        </tbody>
      </table>
      {
        !padToPageSize && table.getRowModel().rows.length === 0 && (
          <div className="flex justify-center items-center p-2 w-full italic">
            {emptyMessage || 'No items to display.'}
          </div>
        )
      }
      {
        (isPaginated && !(!padToPageSize && Math.ceil(totalRowsCount / pageRowCount) <= 1)) ? (
          <div className="flex justify-between items-center px-2 pb-2 mt-2 w-full">
            <div className="space-x-2">
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronDoubleLeftIcon className="w-4 h-4" />
              </Button>
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronLeftIcon className="w-4 h-4" />
              </Button>
            </div>
            {
              isLoading ? (
                <div>Loading...</div>
              ) : (
                padToPageSize ? (
                  table.getRowModel().rows.length === 0 ? (
                    <div>No items to display.</div>
                  ) : (
                    <div>Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</div>
                  )
                ) : (
                  <div>Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</div>
                )
              )
            }

            <div className="space-x-2">
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <ChevronRightIcon className="w-4 h-4" />
              </Button>
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <ChevronDoubleRightIcon className="w-4 h-4" />
              </Button>
            </div>
          </div>
        ) : null
      }
    </div>
  )
}

const SortIcon = ({ isSorted, desc }) => {
  if (isSorted === false) return <ChevronUpDownIcon className="mr-2 w-4 h-4" />
  if (desc === true) return <ChevronDownIcon className="mr-2 w-4 h-4" />
  return <ChevronUpIcon className="mr-2 w-4 h-4" />
}

const Table = ({ data, columns, ...props }) => {
  const columnsMemo = useMemo(() => columns, [columns])
  const dataMemo = useMemo(() => data, [data])

  return (
    <ReactTable
      cols={columnsMemo}
      data={dataMemo}
      {...props}
    />
  )
}

export const PaginatedTable = ({ data, columns, pageSize, ...props }) => {
  const [{ pageIndex }, setPagination] = useState({ pageIndex: 0, pageSize })
  const columnsMemo = useMemo(() => columns, [columns])
  const dataSliced = data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
  const dataMemo = useMemo(() => dataSliced, [dataSliced])

  return (
    <ReactTable
      cols={columnsMemo}
      data={dataMemo}
      isPaginated={true}
      currentPage={pageIndex}
      onPageChange={setPagination}
      totalRowsCount={data.length}
      pageRowCount={pageSize}
      {...props}
    />
  )
}

const TableLink = ({ children, url }) => (
  <Link to={url}>
    <div className="inline-flex items-center text-blue-500 underline">
      <div>{children}</div>
      <ArrowUpRightIcon className="ml-1 w-3 h-3" />
    </div>
  </Link>
)

Table.Link = TableLink

export default Table