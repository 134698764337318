import { useContext } from 'react'
import { useQuery } from 'react-query'

import haxios from './haxios'
import { GlobalContext } from 'context/GlobalContext'
import moment from 'moment'

export const useInvoices = (status = 'all', opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['invoices', status, branch],
    queryFn: ({ queryKey }) => {
      const status = queryKey[1]
      const statusStr = ['allocated', 'unallocated', 'frozen'].includes(status) ? '/' + status : ''
      return haxios.get(`/dd/invoices${statusStr}`, { params: { branch } })
    },
    enabled: !!branch,
    ...opt
  })
}

export const useRuns = (opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['runs', branch],
    queryFn: () => haxios.get('/dd/runs', { params: { branch } }),
    enabled: !!branch,
    ...opt
  })
}

export const useRun = (runId, opt = {}) => useQuery({
  queryKey: ['runs', runId],
  queryFn: () => haxios.get(`/dd/runs/${runId}`),
  ...opt
})

export const useSettings = (opt = {}) => useQuery({
  queryKey: ['settings'],
  queryFn: () => haxios.get(`/dd/settings`),
  ...opt
})

export const useRunLocs = (runId, opt = {}) => useQuery({
  queryKey: ['runs', runId, 'locs'],
  queryFn: () => haxios.get(`/dd/runs/${runId}/locs`),
  ...opt
})

export const useRunCustomers = (runId, opt = {}) => useQuery({
  queryKey: ['runs', runId, 'customers'],
  queryFn: () => haxios.get(`/dd/runs/${runId}/customers`),
  ...opt
})

export const useCustomers = ({ pageSize, pageIndex, name } = {}, opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['customers', branch, name, pageSize, pageIndex],
    queryFn: () => haxios.get('/dd/customers', { params: { branch, name, pageSize, pageIndex } }),
    enabled: !!branch,
    ...opt
  })
}

export const useCustomersPagedLoc = ({ pageSize, pageIndex, name, withLoc } = {}, opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['customers', withLoc, branch, name, pageSize, pageIndex],
    queryFn: () => haxios.get(`/dd/customers/${withLoc}`, { params: { branch, name, pageSize, pageIndex } }),
    enabled: !!branch,
    ...opt
  })
}

export const useCustomersWithoutLocCount = (opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['customers', 'noloc', 'count', branch],
    queryFn: () => haxios.get(`/dd/customers/noloc/count`, { params: { branch } }),
    ...opt
  })
}

export const useCustomer = (acct, opt = {}) => {
  return useQuery({
    queryKey: ['customers', acct],
    queryFn: () => haxios.get(`/dd/customers/${acct}`),
    ...opt
  })
}

export const useCustomersWithLoc = (opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['customers', branch],
    queryFn: () => haxios.get('/dd/customers/loc', { params: { branch } }),
    enabled: !!branch,
    ...opt
  })
}

export const useTrips = (opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['trips', branch],
    queryFn: ({ queryKey }) => {
      return haxios.get(`/dd/trips`, { params: { branch } })
    },
    enabled: !!branch,
    ...opt
  })
}

export const useTripInvoices = (tripId, opt = {}) => useQuery({
  queryKey: ['trips', tripId, 'invoices'],
  queryFn: ({ queryKey }) => {
    const tripId = queryKey[1]
    return haxios.get(`/dd/trips/${tripId}/invoices`)
  },
  ...opt
})

export const useTripDrops = (tripId, opt = {}) => useQuery({
  queryKey: ['trips', tripId, 'drops'],
  queryFn: ({ queryKey }) => {
    const tripId = queryKey[1]
    return haxios.get(`/dd/trips/${tripId}/drops`)
  },
  ...opt
})

export const useBranches = (opt = {}) => useQuery({
  queryKey: ['branches'],
  queryFn: () => haxios.get(`/dd/branches`),
  ...opt
})

export const useVehicles = (opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['vehicles', branch],
    queryFn: () => haxios.get(`/factor/vehicles/getVehicles`, { params: { branch } }),
    ...opt
  })
}

export const useVehicle = (reg, opt = {}) => useQuery({
  queryKey: ['vehicles', reg],
  queryFn: () => haxios.get(`/dd/vehicles/${encodeURIComponent(reg)}`),
  ...opt
})

export const useBulletins = (opt = {}) => useQuery({
  queryKey: ['bulletins'],
  queryFn: () => haxios.get(`/factor/news/getBulletin`),
  ...opt
})

export const useBulletinCustomers = (opt = {}) => useQuery({
  queryKey: ['bulletins', 'customers'],
  queryFn: () => haxios.get(`/factor/getdevicesAndCustomers`),
  ...opt
})

export const usePods = ({ pageSize, pageIndex, acct, driver, invoiceNo, startDate, endDate }, opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['pods', branch, pageSize, pageIndex, acct, driver, invoiceNo, startDate, endDate],
    queryFn: () => haxios.get(`/dd/pods`, { params: { branch, pageSize, pageIndex, acct, driver, invoiceNo, startDate, endDate } }),
    ...opt
  })
}

export const usePodCustomers = (opt = {}) => useQuery({
  queryKey: ['pods', 'customers'],
  queryFn: () => haxios.get(`/dd/pods/customers`),
  ...opt
})

export const usePodDrivers = (opt = {}) => useQuery({
  queryKey: ['pods', 'drivers'],
  queryFn: () => haxios.get(`/dd/pods/drivers`),
  ...opt
})

export const usePodSignature = (podId, opt = {}) => useQuery({
  queryKey: ['pods', podId],
  queryFn: () => haxios.get(`/factor/getPodSignature2/${podId}`),
  ...opt
})

export const useActiveDrivers = (opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['activedrivers', branch],
    queryFn: () => haxios.get(`/factor/getAllActive/${branch}`),
    ...opt
  })
}

export const useCompletedRun = (runId, opt = {}) => {
  return useQuery({
    queryKey: ['activedrivers', runId],
    queryFn: () => haxios.get(`/dd/completedrun/${runId}`),
    ...opt
  })
}

export const useReturns = ({ pageSize, pageIndex, acct }, opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['returns', branch, pageSize, pageIndex, acct],
    queryFn: () => haxios.get(`/dd/returns`, { params: { branch, pageSize, pageIndex, acct } }),
    ...opt
  })
}

export const useReturnCustomers = (opt = {}) => useQuery({
  queryKey: ['returns', 'customers'],
  queryFn: () => haxios.get(`/dd/returns/customers`),
  ...opt
})

export const useLocHistoryRuns = (opt = {}) => useQuery({
  queryKey: ['lochistory'],
  queryFn: () => haxios.get(`/dd/lochistory/runs`),
  ...opt
})

export const useLocHistory = (runId, opt = {}) => useQuery({
  queryKey: ['lochistory', runId],
  queryFn: () => haxios.get(`/dd/lochistory/${runId}`),
  ...opt
})

export const useRewindRun = (runId, opt = {}) => useQuery({
  queryKey: ['rewind', runId],
  queryFn: () => haxios.get(`/dd/rewind/${runId}`),
  ...opt
})

export const useDropImage = (dropImageId, opt = {}) => useQuery({
  queryKey: ['drop', dropImageId],
  queryFn: () => haxios.get(`/factor/getPhoto/${dropImageId}`),
  ...opt
})

export const useDropImageFromDropId = (dropId, opt = {}) => useQuery({
  queryKey: ['drop', dropId, 'photo'],
  queryFn: () => haxios.get(`/dd/drops/${dropId}/photo`),
  ...opt
})

export const usePodImageFromDropId = (dropId, opt = {}) => useQuery({
  queryKey: ['drop', dropId, 'pod'],
  queryFn: () => haxios.get(`/dd/drops/${dropId}/signature`),
  ...opt
})

export const useRewindDropLines = ({ runId, dropId }, opt = {}) => useQuery({
  queryKey: ['rewind', runId, dropId],
  queryFn: () => haxios.get(`/dd/drops/${dropId}`),
  ...opt
})

export const useSearchManifest = (query, opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['rewind', 'search', query, branch],
    queryFn: () => haxios.get(`/factor/searchManifestSave`, { params: { query, branch } }),
    ...opt
  })
}


export const useManifestSendEmails = (opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['manifestsend'],
    queryFn: () => haxios.get(`/factor/manifestSend/${branch}`),
    ...opt
  })
}

export const useCash = (date, opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['cash', date],
    queryFn: () => haxios.get(`/factor/getCash/${branch}/${moment(date).format('YYYY-MM-DD')}`),
    ...opt
  })
}

export const useInvoice = (docNo, opt = {}) => {
  return useQuery({
    queryKey: ['invoices', docNo],
    queryFn: () => haxios.get(`/dd/invoices/${docNo}`),
    ...opt
  })
}

export const useUnallocatedCust = (opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['invoices', 'unallocated', 'cust', branch],
    queryFn: () => haxios.get(`/dd/invoices/unallocated/customers`, { params: { branch } }),
    ...opt
  })
}

export const useUnallocatedCustInvs = (acct, opt = {}) => {
  return useQuery({
    queryKey: ['invoices', 'unallocated', 'cust', acct],
    queryFn: () => haxios.get(`/dd/invoices/unallocated/customers/${acct}`),
    ...opt
  })
}

export const useFrozenInvoiceCount = (opt = {}) => {
  const { branch } = useContext(GlobalContext)

  return useQuery({
    queryKey: ['invoices', 'frozen', 'count'],
    queryFn: () => haxios.get(`/dd/invoices/frozen/count`, { params: { branch } }),
    ...opt
  })
}