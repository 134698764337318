import { useContext, useEffect, useState } from 'react'
import { QueueListIcon, CalendarDaysIcon, MapIcon, TruckIcon, UserGroupIcon, PencilSquareIcon, ReceiptRefundIcon, ArrowPathIcon, CreditCardIcon, NewspaperIcon, MapPinIcon, InboxArrowDownIcon, EyeIcon, HomeIcon, ArrowRightOnRectangleIcon, ClockIcon, UsersIcon } from '@heroicons/react/24/outline'
import { MdOutlinePunchClock } from "react-icons/md";
import clsx from 'clsx'

import { Button, Dropdown } from 'components/gsys-ui'
import { useInvalidateAllQueries, useLogout } from 'util/mutations'
import { GlobalContext } from 'context/GlobalContext'
import { useBranches } from 'util/queries'
import { apir } from 'util/haxios'
import { Link, Outlet } from '@tanstack/react-router'
import { useIsFetching } from 'react-query'
import PermissionHider from './PermissionHider'

const nav = [
  {
    url: '/',
    match: /^\/$/,
    title: 'Assignment',
    Icon: QueueListIcon
  },
  {
    url: '/runs',
    match: /^\/runs\/?.*$/,
    title: 'Run scheduling',
    Icon: CalendarDaysIcon
  },
  {
    url: '/active',
    match: /^\/active\/?.*$/,
    title: 'Active runs',
    Icon: MapIcon
  },
  {
    url: '/vehicles',
    match: /^\/vehicles\/?.*$/,
    title: 'Vehicles',
    Icon: TruckIcon
  },
  {
    url: '/customers',
    match: /^\/customers\/?.*$/,
    title: 'Customers',
    Icon: UserGroupIcon
  },
  {
    url: '/pods',
    match: /^\/pods\/?.*$/,
    title: 'PODs',
    Icon: PencilSquareIcon
  },
  {
    url: '/payments',
    match: /^\/payments\/?.*$/,
    title: 'Payments',
    Icon: CreditCardIcon
  },
  {
    url: '/returns',
    match: /^\/returns\/?.*$/,
    title: 'Returns',
    Icon: ReceiptRefundIcon
  },
  {
    url: '/rewind',
    match: /^\/rewind\/?.*$/,
    title: 'Rewind',
    Icon: ArrowPathIcon
  },
  {
    url: '/manifests',
    match: /^\/manifests\/?.*$/,
    title: 'Manifests',
    Icon: InboxArrowDownIcon
  },
  {
    url: '/bulletins',
    match: /^\/bulletins\/?.*$/,
    title: 'Bulletins',
    Icon: NewspaperIcon
  },
  /*{
    url: '/tma/clocklog',
    match: /^\/tma\/clocklog\/?.*$/,
    title: 'TMA Clocklog',
    Icon: MdOutlinePunchClock,
    permissionKey: 'UserTmaPortalAccess',
    minLevel: 1
  },
  {
    url: '/tma/calendar',
    match: /^\/tma\/calendar\/?.*$/,
    title: 'TMA Calendar',
    Icon: CalendarDaysIcon,
    permissionKey: 'UserTmaPortalAccess',
    minLevel: 1
  },
  {
    url: '/tma/shifts',
    match: /^\/tma\/shifts\/?.*$/,
    title: 'TMA Shifts',
    Icon: ClockIcon,
    permissionKey: 'UserTmaPortalAccess',
    minLevel: 1
  },
  {
    url: '/tma/staff',
    match: /^\/tma\/staff\/?.*$/,
    title: 'TMA Staff',
    Icon: UsersIcon,
    permissionKey: 'UserTmaPortalAccess',
    minLevel: 1
  }*/
  /*{
    url: '/watch',
    title: 'watch',
    Icon: EyeIcon
  }*/
]

const Layout = () => {
  const logout = useLogout()

  return (
    <>
      <header className="fixed top-0 left-0 right-0 h-[55px] px-4 bg-white z-40 flex justify-between items-center border-b border-gray-300">
        <div className="text-2xl font-extrabold">DELIVERY DESK <span className="text-xs">V{process.env.REACT_APP_VERSION_STRING}</span></div>
        <div className="flex-1 text-right">
          <div className="inline-flex justify-end items-center space-x-2">
            {/*<SlidingNav items={nav} />*/}
            <RefreshTimer />
            <BranchDropdown />
            <Button onClick={() => logout.mutate()}>
              <ArrowRightOnRectangleIcon className="mr-1 w-5 h-5" />
              Log out
            </Button>
          </div>
        </div>
      </header>

      <Nav />

      <div className="w-full pl-[73px] pt-[55px] h-full">
        <div className="h-full">
          <Outlet />
        </div>
      </div>
    </>
  )
}

const Nav = () => {
  const [isNavHovered, setNavHovered] = useState(false)

  return (
    <div
      className={clsx(
        'fixed top-0 bottom-0 left-0 bg-white z-30 pt-[55px] border-r border-gray-300 px-4 transition-shadow drop-shadow-none',
        isNavHovered && 'drop-shadow-md'
      )}
      onMouseEnter={() => setNavHovered(true)}
      onMouseLeave={() => setNavHovered(false)}
    >
      <img
        className="py-3 w-10"
        style={{ objectFit: 'cover' }}
        src={`${apir}/public/images/logo.jpg`}
        alt="logo"
      />
      {
        nav.map((item, ind) => {
          const isActive = item.match.test(window.location.pathname)

          return (
            <Link key={ind} to={item.url}>
              <div
                className={clsx(
                  'flex p-2 items-center hover:bg-gray-100 rounded-md cursor-pointer',
                  isActive && '!bg-blue-100 text-blue-600'
                )}
              >
                <item.Icon className="w-6 h-6" />
                <div
                  className={clsx(
                    'overflow-hidden relative w-0 transition-all text-lg h-[28px]',
                    isNavHovered && 'w-48'
                  )}
                >
                  <div className="absolute pl-2">
                    {item.title}
                  </div>
                </div>
              </div>
            </Link>
          )
        })

        /*nav.map((item, ind) => {
          return (
      <PermissionHider permKey={item.permissionKey} minLevel={item.minLevel}>
        <NavLink key={ind} item={item} navIsOpen={false} />
      </PermissionHider>
      )
        })*/
      }
    </div>
  )
}

const NavLink = ({ item, navIsOpen }) => {
  const firstUrlSeg = '/' + window.location.pathname.split('/')[1]

  return (
    <Link to={item.url}>
      <div className={clsx(
        'flex items-center px-4 py-1 text-lg rounded-lg transition-colors cursor-pointer hover:bg-gray-100',
        item.url === firstUrlSeg && 'bg-gray-100 text-blue-500'
      )}>
        <item.Icon className="mr-2 w-5 h-5" /><span>{item.title}</span>
      </div>
    </Link>
  )
}

const BranchDropdown = () => {
  const { isLoading, isError, error, data } = useBranches()
  const { branch, setBranch } = useContext(GlobalContext)

  const options = isLoading || isError ? [] : (
    data.map((branch) => ({
      label: branch.Label,
      value: branch.Name
    }))
  )

  useEffect(() => {
    if (isLoading || isError || !data || data.length === 0) return
    if (!branch) setBranch({ value: data[0].Name })
  }, [data])

  return (
    <Dropdown
      placeholder="Branch"
      value={{ label: branch, value: branch }}
      options={options}
      onChange={setBranch}
    />
  )
}

const RefreshTimer = () => {
  const [timeLeft, setTimeLeft] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [doReset, setDoReset] = useState(false)
  const isFetching = useIsFetching()
  const mutation = useInvalidateAllQueries()

  useEffect(() => {
    const timer = setInterval(() => {
      if (doReset) {
        setTimeLeft(0)
        setDoReset(false)
      } else {
        setTimeLeft(old => old + 1)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [doReset])

  useEffect(() => {
    if (isFetching === 0) setDoReset(true)
  }, [isFetching])

  const handleRefresh = async () => {
    setIsLoading(true)
    await mutation.mutateAsync()
    setIsLoading(false)
  }

  return (
    <>
      <div className="text-xs whitespace-nowrap">Updated {timeLeft} second{timeLeft !== 1 && 's'} ago</div>
      <Button onClick={handleRefresh} loading={isLoading}><ArrowPathIcon className="w-5 h-5" /></Button>
    </>
  )
}

export default Layout