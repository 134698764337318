import { Switch as AntdSwitch } from 'antd'
import { useField } from 'formik'

import s from './Switch.module.scss'

export const Switch = (props) => {
  return (
    <div className={s.inputGroup}>
      {
        props.label && (
          <div className={s.label}>{props.label}</div>
        )
      }
      <AntdSwitch
        {...props}
        className={s.switch}
        checked={props.value}
      />
    </div>
  )
}

export const SwitchFormik = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <Switch
      {...props}
      {...field}
      label={label}
      onChange={helpers.setValue}
    />
  )
}