import { Spinner } from 'components/gsys-ui'

const Loading = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <Spinner />
      <div className="absolute opacity-0 select-none" style={{ fontFamily: 'LicensePlate' }}>load plate font</div>
    </div>
  )
}

export default Loading