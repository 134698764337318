import clsx from 'clsx'

import s from './Checkbox.module.scss'
import Spinner from '../Spinner/Spinner'
import { forwardRef } from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'

const Checkbox = forwardRef((props, ref) => {
  const variant = props.variant || 'blue'
  const disabled = props.disabled || props.loading
  const dark = ['white', 'gray'].includes(variant)

  return (
    <div
      ref={ref}
      className={clsx(
        s.checkboxContainer,
        props.className
      )}
      onClick={props.onClick}
    >
      <div className={s.checkbox} data-checked={props.checked}>
        {
          props.checked && (
            <CheckIcon className={s.check} />
          )
        }
      </div>
    </div>
  )
})

export default Checkbox