import { useField } from 'formik'
import { Editor } from '@tinymce/tinymce-react'

const TextEditor = ({ value, onChange, opt }) => {
  return (
    <Editor
      apiKey='fdstpqvzj4enfbmw4zi4ejd0e5xucai8tc0vb3949vfwr8l6'
      init={{
        paste_data_images: true,
        file_picker_types: 'image',
        /* and here's our custom image picker*/
        file_picker_callback: (cb, value, meta) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          input.addEventListener('change', (e) => {
            const file = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener('load', () => {
              /*
                Note: Now we need to register the blob in TinyMCEs image blob
                registry. In the next release this part hopefully won't be
                necessary, as we are looking to handle it internally.
              */
              const id = 'blobid' + (new Date()).getTime();
              const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
              const base64 = reader.result.split(',')[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            });
            reader.readAsDataURL(file);
          });

          input.click();
        },
        menubar: false,
        resize: false,
        plugins: 'autolink emoticons image link lists media table',
        toolbar: 'undo redo fontfamily fontsize bold italic underline strikethrough link image media table align lineheight checklist numlist bullist indent outdent emoticons',
        ...opt
      }}
      value={value}
      onEditorChange={(content) => onChange(content)}
    />
  )
}

export const TextEditorFormik = (props) => {
  const [field, meta, helpers] = useField(props)

  return (
    <TextEditor
      {...props}
      {...field}
      onChange={helpers.setValue}
    />
  )
}

export default TextEditor