import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useState } from 'react'
import { NewspaperIcon } from '@heroicons/react/24/outline'
import { v4 as uuidv4 } from 'uuid'

import ModalContainer from 'components/ModalContainer'
import { Card, Form } from 'components/gsys-ui'
import { useAddBulletin } from 'util/mutations'
import { bulletinValidation } from 'util/validations'
import BulletinForm from './BulletinForm'


const AddBulletinModal = NiceModal.create(({ afterSubmit = () => { } }) => {
  const modal = useModal()
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const mutation = useAddBulletin()

  const handleSubmit = async (res) => {
    modal.remove()
    afterSubmit()
  }

  return (
    <ModalContainer>
      <Card className="!w-[1200px]">
        <Card.Title Icon={NewspaperIcon}>Add a new bulletin</Card.Title>
        <Card.Body className="!h-[640px]">
          <Form
            validation={bulletinValidation}
            mutation={mutation}
            FormComp={BulletinForm}
            onSubmit={handleSubmit}
            initial={{
              User: 'test@test.com',
              BulletinGuid: uuidv4()
            }}
          />
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default AddBulletinModal