import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { MapIcon } from '@heroicons/react/24/outline'
import GoogleMap from 'google-maps-react-markers'

import ModalContainer from 'components/ModalContainer'
import { fitNwSe } from 'util/helpers'
import { Card, Form } from 'components/gsys-ui'
import MechanicIcon from 'components/MechanicIcon'
import { useEffect, useRef, useState } from 'react'


const ViewMapModal = NiceModal.create(({ name, lat, lon }) => {
  const modal = useModal()

  return (
    <ModalContainer>
      <Card className="!w-[600px]">
        <Card.Title Icon={MapIcon}>POD location</Card.Title>
        <Card.Body className="!h-[640px]">
          <GoogleMap
            apiKey="AIzaSyCuk8jFrOel5RA7Nbyzm0PhD2aZ3VKnanA"
          >
            <MechanicIcon
              lat={lat}
              lng={lon}
            />
          </GoogleMap>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default ViewMapModal