import { useRef } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { Spinner } from 'components/gsys-ui'

const LightboxModal = NiceModal.create(({ useQuery }) => {
  const containerRef = useRef(null)
  const modal = useModal()
  const { isLoading, isError, error, data } = useQuery()

  const handleOnClick = (e) => {
    //if (e.target !== containerRef.current) return
    modal.remove()
  }

  return (
    <div
      className="fixed inset-0 z-50 p-20"
      style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
      ref={containerRef}
      onClick={handleOnClick}
    >
      <div className="absolute top-4 right-4 p-4 text-gray-400 bg-black bg-opacity-0 rounded-full transition-colors cursor-pointer hover:bg-opacity-50 hover:text-white">
        <XMarkIcon className="w-10 h-10" />
      </div>
      {
        isLoading ? (
          <div className="flex justify-center items-center w-full h-full">
            <Spinner color="rgba(255,255,255,0.2)" />
          </div>
        ) : (
          <img className="object-contain w-full h-full" src={'data:image/jpeg;base64,' + data} alt="close" />
        )
      }
    </div>
  )
})

export default LightboxModal