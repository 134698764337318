import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { apir } from 'util/haxios'

import ModalContainer from 'components/ModalContainer'
import { Button, Card, Form } from 'components/gsys-ui'
import { usePodImageFromDropId, usePodSignature } from 'util/queries'


const ViewSignatureModal = NiceModal.create(({ podId, dropId }) => {
  const modal = useModal()
  const { isLoading: podIsLoading, isError: podIsError, error: podError, data: podData } = usePodSignature(podId, { enabled: !!podId })
  const { isLoading: dropIsLoading, isError: dropIsError, error: dropError, data: dropData } = usePodImageFromDropId(dropId, { enabled: !!dropId })

  if (podIsLoading || dropIsLoading) return null
  const data = podData || dropData

  return (
    <ModalContainer>
      <Card className="!w-[540px]">
        <Card.Title Icon={PencilSquareIcon}>
          <div>POD signature</div>
          <div>{data.sigName}</div>
        </Card.Title>
        <Card.Body>
          <img src={data.signature} className="my-2 rounded-md border border-gray-300 h-[451px] w-[511px] mx-auto" />
          <div className="flex justify-end">
            <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Close</Button>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default ViewSignatureModal