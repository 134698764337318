import clsx from 'clsx'

import s from './Badge.module.scss'

const Badge = ({ children, ...props }) => {
  const variant = props.variant ?? 'blue'

  return (
    <div
      className={
        clsx(
          s.badge,
          s[variant],
          props.rounded && s.rounded,
          props.className
        )
      }
      style={props.style}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
    >
      {children}
    </div>
  )
}

export default Badge