import { toast } from 'react-hot-toast'
import { useQueryClient, useMutation } from 'react-query'

import haxios, { getToken } from 'util/haxios'
import { successToast } from './helpers'
import { useContext } from 'react'
import { GlobalContext } from 'context/GlobalContext'

export const useInvalidateAllQueries = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => { },
    onSuccess: () => {
      return queryClient.invalidateQueries()
    }
  })
}

export const useUpdateSetting = (key) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => haxios.put(`/dd/settings/${key}`, body),
    onSuccess: () => {
      return queryClient.invalidateQueries(['settings'])
    }
  })
}

export const useLogin = () => {
  return useMutation({
    mutationFn: (body) => getToken(body.username, body.password, false)
  })
}

export const useLogout = () => {
  return useMutation({
    mutationFn: () => localStorage.removeItem('token'),
    onSuccess: () => window.location = '/login'
  })
}

export const useCreateRun = () => {
  const queryClient = useQueryClient()
  const { branch } = useContext(GlobalContext)

  return useMutation({
    mutationFn: (body) => haxios.post('/dd/runs', { ...body, Branch: branch }),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['runs'] })
    }
  })
}

export const useDeleteRun = () => {
  const queryClient = useQueryClient()
  const { branch } = useContext(GlobalContext)

  return useMutation({
    mutationFn: (runId) => haxios.delete(`/dd/runs/${runId}`),
    onSuccess: (_, [runId]) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['runs', branch], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['runs', runId] })
      ])
    }
  })
}

export const useAddVehicle = () => {
  const queryClient = useQueryClient()
  const { branch } = useContext(GlobalContext)

  return useMutation({
    mutationFn: (body) => haxios.post(`/factor/vehicles/addVehicle`, { ...body, Branch: branch }),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['vehicles'] })
      ])
    }
  })
}

export const useUpdateVehicle = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => haxios.post(`/factor/vehicles/addVehicle`, body),
    onSuccess: (data, vars) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['vehicles'] }),
      ])
    }
  })
}

export const useDeleteVehicle = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (reg) => haxios.delete(`/dd/vehicles/${reg}`),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['vehicles'] })
      ])
    }
  })
}

export const useDeleteInvoice = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (docNo) => haxios.delete(`/dd/invoices/${docNo}`),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['factor', 'iHeads'] })
    }
  })
}

export const useDeleteTrip = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (tripId) => haxios.delete(`/dd/trips/${tripId}`),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['trips'] })
    }
  })
}

export const useUpdateTrip = () => {
  const queryClient = useQueryClient()
  const { branch } = useContext(GlobalContext)

  return useMutation({
    mutationFn: ({ tripId, data }) => haxios.patch(`/dd/trips/${tripId}`, data),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['trips'] })
    }
  })
}

export const useAddCustomerToRun = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ runId, acct }) => haxios.post(`/dd/runs/${runId}/customers`, { acct }),
    onSuccess: (data, vars) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['runs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['runs', vars.runId] }),
      ])
    }
  })
}

export const useRemoveCustomerFromRun = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ runId, acct }) => haxios.delete(`/dd/runs/${runId}/customers/${acct}`),
    onSuccess: (data, vars) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['runs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['runs', vars.runId] }),
      ])
    }
  })
}

export const useUpdateRunDays = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ runId, scheduleId, days }) => haxios.put(`/dd/runs/${runId}/schedules/${scheduleId}/days`, days),
    onMutate: async ({ runId, scheduleId, days }) => {
      await queryClient.cancelQueries({ queryKey: ['runs'] })
      const prevRun = queryClient.getQueryData(['runs', runId])
      const scheduleInd = prevRun.Schedules.findIndex((el) => el._id === scheduleId)

      const newRun = {
        ...prevRun,
        Schedules: [
          ...prevRun.Schedules.slice(0, scheduleInd),
          {
            ...prevRun.Schedules[scheduleInd],
            Days: days
          },
          ...prevRun.Schedules.slice(scheduleInd + 1, prevRun.Schedules.length)
        ]
      }

      queryClient.setQueryData(['runs', runId], newRun)
      return { prevRun }
    },
    onError: (err, vars, context) => {
      console.log(err)
      queryClient.setQueryData(['runs', vars.runId], context.prevRun)
    },
    onSettled: (data, err, vars) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['runs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['runs', vars.runId] }),
      ])
    }
  })
}

export const useAddRunSchedule = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ runId }) => haxios.post(`/dd/runs/${runId}/schedules`),
    onSuccess: (data, vars) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['runs', vars.runId] }),
      ])
    }
  })
}

export const useDeleteRunSchedule = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ runId, scheduleId }) => haxios.delete(`/dd/runs/${runId}/schedules/${scheduleId}`),
    onSuccess: (data, vars) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['runs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['runs', vars.runId] }),
      ])
    }
  })
}

export const useAddTimeToRun = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ runId, scheduleId, time }) => haxios.post(`/dd/runs/${runId}/schedules/${scheduleId}/times`, { time }),
    onSuccess: (data, vars) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['runs', vars.runId] }),
      ])
    }
  })
}

export const useDeleteTimeFromRun = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ runId, scheduleId, time }) => haxios.delete(`/dd/runs/${runId}/schedules/${scheduleId}/times`, { data: { time } }),
    onSuccess: (data, vars) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['runs', vars.runId] }),
      ])
    }
  })
}

export const useMoveInvoice = () => {
  const queryClient = useQueryClient()
  const { branch } = useContext(GlobalContext)

  return useMutation({
    mutationFn: ({ docNo, destTrip, status }) => haxios.patch(`/dd/invoices/${docNo}`, { Status: status, DDTripId: destTrip }),
    onSuccess: async (data, vars) => {
      let promises = [
        queryClient.invalidateQueries({ queryKey: ['trips'] }),
        queryClient.invalidateQueries({ queryKey: ['invoices'] })
      ]

      await Promise.all(promises)
      successToast(`Invoice ${vars.docNo} moved successfully.`)
    }
  })
}

export const useUpdateInvoiceStatus = () => {
  const queryClient = useQueryClient()
  const { branch } = useContext(GlobalContext)

  return useMutation({
    mutationFn: ({ docNo, status }) => haxios.patch(`/dd/invoices/${docNo}`, { Status: status, StatusDetail: 'manual' }),
    onSuccess: async (data, vars) => {
      let promises = [
        queryClient.invalidateQueries({ queryKey: ['trips', branch], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['trips', vars.source, 'drops'] })
      ]

      const isFromTrip = !['unallocated', 'frozen'].includes(vars.source)

      if (isFromTrip) {
        promises.push(queryClient.invalidateQueries({ queryKey: ['trips', vars.source, 'invoices'] }))
      } else {
        if (vars.source === 'unallocated') {
          promises.push(queryClient.invalidateQueries({ queryKey: ['invoices', 'unallocated', 'cust', branch], exact: true }))
          promises.push(queryClient.invalidateQueries({ queryKey: ['invoices', 'unallocated', 'cust', vars.acct], exact: true }))
          promises.push(queryClient.invalidateQueries({ queryKey: ['invoices', 'unallocated', branch], exact: true }))
          //promises.push(queryClient.invalidateQueries({ queryKey: ['invoices', 'unallocated', 'cust', ] }))
        } else {
          promises.push(queryClient.invalidateQueries({ queryKey: ['invoices', vars.source] }))
        }
      }

      await Promise.all(promises)
      queryClient.refetchQueries({ queryKey: ['invoices', vars.status], exact: true })
      successToast(`Invoice ${vars.docNo} moved successfully.`)
    }
  })
}

export const useAddBulletin = () => {
  const queryClient = useQueryClient()
  const { branch } = useContext(GlobalContext)

  return useMutation({
    mutationFn: (body) => haxios.post(`/factor/news/addBulletin`, { ...body, Branch: branch }),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['bulletins'], exact: true })
      ])
    }
  })
}

export const useUpdateBulletin = () => {
  const queryClient = useQueryClient()
  const { branch } = useContext(GlobalContext)

  return useMutation({
    mutationFn: (body) => haxios.post(`/factor/news/updateBulletin`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['bulletins'], exact: true })
      ])
    }
  })
}

export const useSendBulletin = (bulletinId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => haxios.post(`/factor/news/sendBulletin/${bulletinId}`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['bulletins'], exact: true })
      ])
    }
  })
}

export const useDeleteBulletin = (bulletinId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => haxios.delete(`/factor/news/deleteBulletin/${bulletinId}`),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['bulletins'], exact: true })
      ])
    }
  })
}

export const useDeleteManifestSend = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (manifestSendId) => haxios.delete(`/factor/deleteManifestSend/${manifestSendId}`),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['manifestsend'] })
      ])
    }
  })
}

export const useAddManifestSend = () => {
  const queryClient = useQueryClient()
  const { branch } = useContext(GlobalContext)

  return useMutation({
    mutationFn: (email) => haxios.post(`/factor/addManifestSend`, { Date: Date.now(), Branch: branch, Email: email }),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['manifestsend'] })
      ])
    }
  })
}

export const useUpdateCash = (cashId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => haxios.put(`/factor/updateCash/${cashId}`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['cash'] })
      ])
    }
  })
}

export const useUpdateCustomerLoc = (acct) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ loc, addra, pcode }) => haxios.put(`/dd/customers/${acct}/loc`, { loc, addra, pcode }),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['customers'] })
    }
  })
}