import { useRunLocs } from 'util/queries'
import MechanicIcon from 'components/MechanicIcon'
import Map from 'components/Map'

const RunCustomerMap = ({ runId }) => {
  const { isLoading, isError, error, data } = useRunLocs(runId, { enabled: !!runId })
  const points = data ? data.map((el) => el.Loc) : []

  return (
    <Map points={points}>
      {
        data && data
          .map((el, ind) => {
            if (!el.Loc) return null

            return (
              <MechanicIcon
                key={ind}
                lat={el.Loc.lat}
                lng={el.Loc.lon}
                name={el.Name}
              />
            )
          })
      }
    </Map>
  )
}

export default RunCustomerMap