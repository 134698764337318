import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { DocumentTextIcon, ListBulletIcon, XMarkIcon } from '@heroicons/react/24/outline'

import ModalContainer from 'components/ModalContainer'
import { Button, Card, Table } from 'components/gsys-ui'
import { useInvoice } from 'util/queries'

const ViewLinesModal = NiceModal.create(({ docNo }) => {
  const modal = useModal()
  const { isLoading, isError, error, data } = useInvoice(docNo)

  if (isLoading) return null

  return (
    <ModalContainer>
      <Card className="!w-[600px]">
        <Card.Title Icon={ListBulletIcon}>
          Invoice details
        </Card.Title>
        <Card.Body>
          <div className="flex items-center mt-2 font-bold">
            <DocumentTextIcon className="mr-1 w-6 h-6" />
            <div>Invoice {docNo}</div>
          </div>
          <LinesTable data={data} />
          <div className="flex justify-end mt-2">
            <Button Icon={XMarkIcon} variant="white" onClick={modal.remove}>Close</Button>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

const LinesTable = ({ data }) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'PART',
          accessorKey: 'Part'
        },
        {
          header: 'QTY',
          accessorKey: 'Qty'
        },
        {
          header: 'DESCRIPTION',
          accessorKey: 'Desc'
        }
      ]}
    />
  )
}

export default ViewLinesModal