import * as Yup from 'yup'

Yup.addMethod(Yup.mixed, 'requiredPatchable', function (msg, def) {
  return this.when('$isPatch', (isPatch, fieldSchema) => {
    if (isPatch) return fieldSchema.notRequired()
    if (def !== undefined) return fieldSchema.required(msg).default(def)
    return fieldSchema.required(msg)
  })
})

Yup.addMethod(Yup.mixed, 'emptyToNull', function () {
  return this.transform((value) => value === '' || value === 0 || isNaN(value) ? null : value)
})

export const loginValidation = Yup.object({
  username: Yup.string()
    .min(5, 'Username must be at least 5 characters')
    .max(254, 'Username must be less than 254 characters')
    .required('Please enter a username')
    .default(''),
  password: Yup.string()
    .required('Please enter a password')
    .default('')
})

export const vehicleValidation = Yup.object({
  Reg: Yup.string()
    .default(''),
  Make: Yup.string()
    .default(''),
  Model: Yup.string()
    .default(''),
  Colour: Yup.string()
    .default(''),
  FuelCardNo: Yup.string()
    .default(''),
  FuelCardPin: Yup.string()
    .default(''),
  FuelCardExpiry: Yup.date()
    .nullable()
    .default(null),
  FuelCardSupplier: Yup.string()
    .default(''),
  LastMileage: Yup.number()
    .integer()
    .nullable()
    .default(null),
  Area: Yup.string()
    .default(''),
  LastService: Yup.date()
    .nullable()
    .default(null),
  LastCheckList: Yup.date()
    .nullable()
    .default(null),
  LastCheckListBy: Yup.string()
    .default(''),
  CheckListSchedule: Yup.string()
    .default(''),
  IsShared: Yup.boolean()
    .default(false)
})

export const bulletinValidation = Yup.object({
  Name: Yup.string()
    .default(''),
  Notes: Yup.string()
    .default(''),
  DateTime: Yup.date()
    .nullable()
    .default(new Date()),
  Expiry: Yup.date()
    .nullable()
    .default(new Date()),
  User: Yup.string()
    .default(''),
  Content: Yup.string()
    .default(''),
  BulletinGuid: Yup.string()
})