
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel } from '@tanstack/react-table'
import { ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon, ArrowUpRightIcon, ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Button, Spinner } from 'components/gsys-ui'
import { useMemo, useRef, useState } from 'react'
import { Link } from '@tanstack/react-router'
import { useDraggable } from '@dnd-kit/core'
import { useVirtualizer } from '@tanstack/react-virtual'

const ReactTable = ({
  data,
  cols,
  isPaginated,
  isCompact,
  notFull = false,
  currentPage,
  onPageChange,
  onRowClick = () => { },
  totalRowsCount,
  padToPageSize,
  emptyMessage,
  thinRows,
  isLoading,
  tableFixed = false,
  pageRowCount = 10,
  dummyRowHeight,
  dragData = {},
  rowHighlight = true,
  showPaginationControls = true,
  columnVisibility = {}
}) => {
  const table = useReactTable({
    data,
    columns: cols,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: !!isPaginated,
    pageCount: isPaginated ? Math.ceil(totalRowsCount / pageRowCount) : -1,
    onPaginationChange: !!isPaginated && onPageChange,
    state: {
      pagination: !!isPaginated && { pageIndex: currentPage, pageSize: pageRowCount },
      columnVisibility
    }
  })

  const { rows } = table.getRowModel()
  const parentRef = useRef(null)

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 24,
    overscan: 30
  })

  return (
    <div ref={parentRef} className={clsx('select-none overflow-auto', notFull && 'h-full')}>
      <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
        <table className={clsx('relative whitespace-nowrap', !notFull && 'w-full', notFull && 'min-w-full', tableFixed && 'table-fixed')}>
          <thead>
            {
              table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {
                    headerGroup.headers.map(header => (
                      <th
                        key={header.id}
                        className={clsx(
                          'border-b-2 border-b-grey-400 text-sm font-bold text-left sticky top-0 bg-white z-50 drop-shadow-sm py-0.5 px-2',
                          header.column.columnDef.noPadAnywhere === true && '!p-0'
                        )}
                      >
                        {
                          header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )
                        }
                      </th>
                    ))}
                </tr>
              ))}
          </thead>
          <tbody className="">
            {
              virtualizer.getVirtualItems().map((virtualRow, index) => {
                const row = rows[virtualRow.index]

                return (
                  <Draggable
                    key={row.id}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                      boxShadow: '0px -1px 0px 0px rgba(209,213,219,0.9)',
                      //marginBottom: '1px'
                    }}
                    id={row.original.Document}
                    data={dragData}
                  >
                    {
                      row.getVisibleCells().map(cell => {
                        const text = flexRender(cell.column.columnDef.cell, cell.getContext())

                        return (
                          <td
                            key={cell.id}
                            className={clsx(
                              'relative text-sm py-0.5 px-2',
                              cell.column.columnDef.cellPad !== false && !thinRows && 'py-1.5 px-2',
                              cell.column.columnDef.cellPad !== false && thinRows && 'py-0.5 px-2',
                              cell.column.columnDef.noPadAnywhere === true && '!p-0',
                              cell.column.columnDef.noWrap && 'whitespace-nowrap'
                            )}
                            style={{
                              width: cell.column.columnDef.cellSize ? `${cell.column.columnDef.cellSize}px` : undefined,
                            }}
                          >
                            {
                              cell.column.columnDef.truncate ? (
                                <span className="absolute inset-0 leading-8 truncate" title={cell.getValue()}>{text}</span>
                              ) : text
                            }
                          </td>
                        )
                      })}
                  </Draggable>
                )
              })
            }
            {
              isLoading && (
                <div className="flex absolute inset-0 justify-center items-center bg-white">
                  <Spinner />
                </div>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

const Draggable = ({ id, data, style, children }) => {
  const { setNodeRef, listeners, transform, attributes } = useDraggable({ id, data })
  const styleT = transform ? { ...style, opacity: '30%' } : style

  return (
    <tr
      className={clsx('transition-opacity hover:bg-blue-50')}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={styleT}
    >
      {children}
    </tr>
  )
}

const SortIcon = ({ isSorted, desc }) => {
  if (isSorted === false) return <ChevronUpDownIcon className="mr-2 w-4 h-4" />
  if (desc === true) return <ChevronDownIcon className="mr-2 w-4 h-4" />
  return <ChevronUpIcon className="mr-2 w-4 h-4" />
}

const Table = ({ data, columns, ...props }) => {
  const columnsMemo = useMemo(() => columns, [columns])
  const dataMemo = useMemo(() => data, [data])

  return (
    <ReactTable
      cols={columnsMemo}
      data={dataMemo}
      {...props}
    />
  )
}

export const PaginatedTable = ({ data, columns, pageSize, ...props }) => {
  const [{ pageIndex }, setPagination] = useState({ pageIndex: 0, pageSize })
  const columnsMemo = useMemo(() => columns, [columns])
  const dataSliced = data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
  const dataMemo = useMemo(() => dataSliced, [dataSliced])

  return (
    <ReactTable
      cols={columnsMemo}
      data={dataMemo}
      isPaginated={true}
      currentPage={pageIndex}
      onPageChange={setPagination}
      totalRowsCount={data.length}
      pageRowCount={pageSize}
      {...props}
    />
  )
}

const TableLink = ({ children, url }) => (
  <Link to={url}>
    <div className="inline-flex items-center text-blue-500 underline">
      <div>{children}</div>
      <ArrowUpRightIcon className="ml-1 w-3 h-3" />
    </div>
  </Link>
)

Table.Link = TableLink

export default Table