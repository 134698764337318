import { useContext, useState } from 'react'

import haxios from 'util/haxios'
import { Dropdown } from 'components/gsys-ui'
import { useCustomers } from 'util/queries'
import { GlobalContext } from 'context/GlobalContext'

const CustomerDropdown = ({ selectedCustomer, setSelectedCustomer }) => {
  const { branch } = useContext(GlobalContext)
  
  const loadOptions = async (search, loadedOptions, { page }) => {
    const res = await haxios.get('/dd/customers', { params: { branch, name: search, pageSize: 15, pageIndex: page - 1 } })
    
    return {
      options: res.response.map((p) => ({
        label: `[${p.KeyCode}] ${p.Name}`,
        value: p.KeyCode
      })),
      hasMore: page < res.pagination.totalPages,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <div className="flex space-x-2">
      <div className="flex-1 min-w-0">
        <Dropdown
          placeholder="Type to search..."
          value={selectedCustomer}
          onChange={setSelectedCustomer}
          loadOptions={loadOptions}
          withPagination={true}
          additional={{
            page: 1,
          }}
        />
      </div>
    </div>
  )
}

export default CustomerDropdown